<template>
    <b-form-group :label="label"
        :invalid-feedback="errorTxt"
        :state="state">
        <template #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <div class='input-group mb-1'>
            <input type="text" class="form-control form-control-sm" v-model="newItem" placeholder="Nhập giá trị để thêm mới" v-on:keyup="newItemEnter"/>
            <div class='input-group-append'>
                <button type="button" class="btn btn-sm btn-primary" @click="addNewItem">Thêm mới</button>
            </div>  
        </div>
        <div class="bs-component">
            <ul class="list-group">
                <draggable v-model="inputVal" :state="state" :readonly="readonly">
                    <li class="list-group-item" v-for="(item, index) in inputVal" :key="'op'+index" style="cursor: move">
                        <i class="fa fa-angle-right"></i>
                        {{item}}
                        <div class="float-right" style="margin-top: -5px; margin-right: -15px;">
                            <button type="button" class="btn-sm btn btn-sm btn-outline-gray mr-1">
                                <i class="fa fa-arrows-alt mr-0"></i>
                            </button>
                            <button @click="removeItem(index)" type="button" class="btn-sm btn btn-sm btn-outline-danger mr-1">
                                <i class="fa fa-trash-alt mr-0"></i>
                            </button>
                        </div>
                    </li>
                </draggable>
            </ul>
        </div>
    </b-form-group>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        components: {draggable},
        props: ['model', 'attribute', 'label', 'type', 'placeholder', 'errors', 'readonly', 'errorOnly', 'required'],
        data() {
            return {
                inputVal: this.model[this.attribute] ? this.model[this.attribute] : null,
                state: null,
                errorTxt: '',
                newItem: null,
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            addNewItem: function () {
                if (this.model[this.attribute] === null) {
                    this.model[this.attribute] = [];
                }

                let cf = this.model[this.attribute].find(item => {
                    return item === this.newItem;
                });
                if (typeof (cf) !== 'undefined') {
                    this.newItem = null;
                } else if (this.newItem) {
                    this.model[this.attribute].push(this.newItem);
                    this.newItem = null;
                }
            },
            newItemEnter: function (e) {
                if (e.keyCode === 13) {
                    this.addNewItem();
                }
            },
            removeItem: function (index) {
                if (this.model[this.attribute] && this.model[this.attribute].length) {
                    this.model[this.attribute].splice(index, 1);
                }
            }
        }
    }
</script>