export default {
    data: function () {
        return {
        }
    },
    methods: {
        isPrime() {
            return ['prime'].includes(this.$store.state.client.type);
        },
        isSub() {
            return ['sub'].includes(this.$store.state.client.type);
        },
        isTW() {
            return ['tw'].includes(this.$store.state.client.type);
        },
        isUsaid() {
            return ['usaid'].includes(this.$store.state.client.type);
        },
        permissions: function (...roles) {
            if (!this.$store.state.user) {
                return false;
            }
            if (!this.$store.state.authEnable) {
                return true;
            }
            for (let role of roles) {
                let hasRole = this.$store.state.roles.find(r => r === role);
                if (!hasRole) {
                    return false;
                }
            }
            return true;
        },
        hasIndicator: function (...roles) {
            if (!this.$store.state.user || !this.$store.state.client) {
                return false;
            }
            if(!this.$store.state.client.indicators || !this.$store.state.client.indicators.length) {
                return true;
            }
            let hasRole = false;
            for (let role of roles) {
                for (let code in this.$params.indicator) {
                    if(!this.$store.state.client.indicators.includes(code)) {
                        continue;
                    }
                    let indicator = this.$params.indicator[code];
                    if (indicator.role.includes(role)) {
                        hasRole = true;
                        continue;
                    }
                }
            }
            return hasRole;
        },
    },
};