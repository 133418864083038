<template>
    <date-picker v-model="inputVal" :type="type"
                 @input="input" value-type="timestamp"
                 :clearable="false" :shortcuts="shortcuts"  range>
        <template v-slot:input>
            <button class="btn btn-outline-gray btn-sm is-border-1 dropdown-toggle" name="date" type="button">
                <i class="fa fa-calendar-alt icon-left"></i>&nbsp;
                <span v-if="label">{{ label }}</span>
                <span v-if="label&&selected">: </span>
                <span>{{ selected }}</span>
            </button>
        </template>
        <template v-slot:icon-calendar>&nbsp;</template>
    </date-picker>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/vi';
    import moment from 'moment';
    import _ from 'lodash';

    export default {
        components: {DatePicker},
        props: {
            type: {
                type: String,
                default: 'date'
            },
            model: Object,
            fromAttribute: {
                type: String,
                default: 'from'
            },
            toAttribute: {
                type: String,
                default: 'to'
            },
            label: String,
            defaultShortcut: Number,
        },
        data() {
            return {
                inputVal: [null, null],
                selected: null,
                shortcuts: [
                    {
                        text: "Hôm nay",
                        onClick: () => {
                            return [
                                moment().startOf('day').toDate(),
                                moment().endOf('day').toDate()
                            ];
                        },
                    },
                    {
                        text: "Hôm qua",
                        onClick: () => {
                            return [
                                moment().subtract('1', 'day').startOf('day').toDate(),
                                moment().subtract('1', 'day').endOf('day').toDate()
                            ];
                        },
                    },
                    {
                        text: "7 ngày qua",
                        onClick: () => {
                            return [
                                moment().subtract('6', 'day').startOf('day').toDate(),
                                moment().endOf('day').toDate()
                            ];
                        },
                    },
                    {
                        text: "30 ngày qua",
                        onClick: () => {
                            return [
                                moment().subtract('29', 'day').startOf('day').toDate(),
                                moment().endOf('day').toDate()
                            ];
                        },
                    },
                    {
                        text: "Tuần này",
                        onClick: () => {
                            return [
                                moment().startOf('week').toDate(),
                                moment().endOf('week').toDate()
                            ];
                        },
                    },
                    {
                        text: "Tuần trước",
                        onClick: () => {
                            return [
                                moment().subtract('1', 'week').startOf('week').toDate(),
                                moment().subtract('1', 'week').endOf('week').toDate()
                            ];
                        },
                    },
                    {
                        text: "Tháng này",
                        onClick: () => {
                            return [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate()
                            ];
                        },
                    },
                    {
                        text: "Tháng trước",
                        onClick: () => {
                            return [
                                moment().subtract('1', 'month').startOf('month').toDate(),
                                moment().subtract('1', 'month').endOf('month').toDate()
                            ];
                        },
                    },
                    {
                        text: "Năm nay",
                        onClick: () => {
                            return [
                                moment().subtract('0', 'years').startOf('years').toDate(),
                                moment().subtract('0', 'years').endOf('years').toDate()
                            ];
                        },
                    },
                    {
                        text: "Năm trước",
                        onClick: () => {
                            return [
                                moment().subtract('1', 'years').startOf('years').toDate(),
                                moment().subtract('1', 'years').endOf('years').toDate()
                            ];
                        },
                    },
                    {
                        text: "Toàn bộ",
                        onClick: () => {
                            return [null, null];
                        },
                    },
                ],
            };
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = [];
                    this.inputVal.push(val[this.fromAttribute] ? val[this.fromAttribute] : null);
                    this.inputVal.push(val[this.toAttribute] ? val[this.toAttribute] : null);
                    let shortcut;
                    try {
                        shortcut = this.shortcuts.find(s => s.onClick()[0].getTime() == this.model[this.fromAttribute] && s.onClick()[1].getTime() == this.model[this.toAttribute]);
                    } catch (e) {
                        shortcut = null;
                    }

                    if (!this.inputVal[0] && !this.inputVal[1]) {
                        this.selected = "Toàn bộ";
                    } else if (shortcut) {
                        this.selected = shortcut.text;
                    } else if (this.type === 'year') {
                        this.selected = `${moment(this.inputVal[0], 'x').format('YYYY')} ~ ${moment(this.inputVal[1], 'x').format('YYYY')}`;
                    } else {
                        this.selected = `${moment(this.inputVal[0], 'x').format('DD/MM/YYYY')} ~ ${moment(this.inputVal[1], 'x').format('DD/MM/YYYY')}`;
                    }
                }, deep: true
            },
        },
        methods: {
            input() {
                if (this.inputVal[0] && this.inputVal[1]) {
                    if (this.type === 'year') {
                        //Last year
                        this.inputVal[1] = parseInt(moment(this.inputVal[1], 'x').subtract('0', 'years').endOf('years').format('x'));
                    }
                    this.model[this.fromAttribute] = parseInt(moment(this.inputVal[0], 'x').startOf('day').format('x'));
                    this.model[this.toAttribute] = parseInt(moment(this.inputVal[1], 'x').endOf('day').format('x'));

                    let shortcut;
                    try {
                        shortcut = this.shortcuts.find(s => s.onClick()[0].getTime() == this.model[this.fromAttribute] && s.onClick()[1].getTime() == this.model[this.toAttribute]);
                    } catch (e) {
                        shortcut = null;
                    }

                    if (shortcut) {
                        this.selected = shortcut.text;
                    } else if (this.type === 'year') {
                        this.selected = `${moment(this.inputVal[0], 'x').format('YYYY')} ~ ${moment(this.inputVal[1], 'x').format('YYYY')}`;
                    } else {
                        this.selected = `${moment(this.inputVal[0], 'x').format('DD/MM/YYYY')} ~ ${moment(this.inputVal[1], 'x').format('DD/MM/YYYY')}`;
                    }
                } else {
                    this.model[this.fromAttribute] = null;
                    this.model[this.toAttribute] = null;
                    this.selected = null;
                }
                this.$emit('change');
            },
            setSelected: _.debounce(function (text) {
                this.selected = text;
            }, 50),
        },
        mounted() {
            if (this.type === 'year') {
                this.shortcuts = [
                    {
                        text: "Năm nay",
                        onClick: () => {
                            return [
                                moment().subtract('0', 'years').startOf('years').toDate(),
                                moment().subtract('0', 'years').endOf('years').toDate()
                            ];
                        },
                    },
                    {
                        text: "Năm trước",
                        onClick: () => {
                            return [
                                moment().subtract('1', 'years').startOf('years').toDate(),
                                moment().subtract('1', 'years').endOf('years').toDate()
                            ];
                        },
                    },
                    {
                        text: "Cách 5 năm",
                        onClick: () => {
                            return [
                                moment().subtract('5', 'years').startOf('years').toDate(),
                                moment().subtract('0', 'years').endOf('years').toDate()
                            ];
                        },
                    },
                    {
                        text: "Cách 10 năm",
                        onClick: () => {
                            return [
                                moment().subtract('10', 'years').startOf('years').toDate(),
                                moment().subtract('0', 'years').endOf('years').toDate()
                            ];
                        },
                    },
                    {
                        text: "Toàn bộ",
                        onClick: () => {
                            return [null, null];
                        },
                    },
                ];
            }
            if (!isNaN(this.defaultShortcut)) {
                let shortcut = this.shortcuts[this.defaultShortcut];
                if (shortcut) {
                    let times = shortcut.onClick();
                    this.inputVal = [times[0] ? times[0].getTime() : null, times[1] ? times[1].getTime() : null];
                    this.selected = shortcut.text;
                    this.model[this.fromAttribute] = this.inputVal[0];
                    this.model[this.toAttribute] = this.inputVal[1];
                    if (this.inputVal[0] != null && this.inputVal[1] != null) {
                        this.$emit('change');
                    }
                }
            }
        }
    }
</script>