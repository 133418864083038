<template>
    <nav class="c-header main-header navbar navbar-expand-md text-sm navbar-cyan navbar-dark">
        <div class="container">
            <a href="#/index" class="navbar-brand">
                <img v-if='$store.state.etc["site-logo"]' :src='$store.state.etc["site-logo"]' class="brand-image" style="opacity: .8">
                <span class="brand-text font-weight-light" v-else-if='$store.state.client'>
                    {{$store.state.client.short_name}}
                    <small style="font-size: 12px;display: block;">{{$params.client.typeOptions.find(o => o.value === $store.state.client.type).text}}</small>
                </span>
                <span class="brand-text font-weight-light" v-else-if='$store.state.etc["site-name"]'>{{$store.state.etc["site-name"]}}</span>
                <span v-else class="brand-text font-weight-light">{{appName}}</span>
            </a>

            <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse order-3" id="navbarCollapse">
                <ul class="navbar-nav">
                    <template v-for="item in $store.state.user.menus">
                        <router-link v-if="item.url" :key="`p_${item.label}`" :to="item.url" v-slot="{href, navigate,isActive}">
                            <li :class="[isActive ?'nav-item active':'nav-item', item.items ? 'dropdown' : '']">
                                <a v-if='!item.items' :href="href" class="nav-link" @click="navigate">
                                    <em v-if='item.icon' :class='item.icon'></em> {{item.label}}
                                </a>
                                <a v-else class="nav-link dropdown-toggle" :class="[isActive ? 'active' : '']" :href="href">
                                    <em v-if='item.icon' :class='item.icon'></em> {{item.label}}
                                </a>
                                <div v-if='item.items'  class="dropdown-menu is-custom border-0 shadow">
                                    <div class='dropdown-menu__inner'>
                                        <ul class='ul-list'>
                                            <router-link v-for="(i, index) in item.items" :key="`c_${index}`" :to="i.url"
                                                         v-slot="sub">
                                                <li v-if='hasRole(sub.href)' class="li-icon" >
                                                    <a class="treeview-item" :class='[sub.isActive ? "active" : ""]' :href="sub.href" @click="sub.navigate">
                                                        <em v-if='i.icon' :class='i.icon'></em> {{ i.label }}
                                                    </a>
                                                </li>
                                            </router-link>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </router-link>
                    </template>
                </ul>
            </div>

            <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link" data-toggle="dropdown" href="#">
                        <figure class="c-avatar">{{getAvatar()}}</figure>
                        {{$store.state.user.name}}
                        <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
                    </a>
                    <div class="dropdown-menu is-custom dropdown-menu-lg dropdown-menu-right">
                        <div class='dropdown-menu__inner'>
                            <a href="#/setting/profile" class="dropdown-item">
                                <b-icon icon="person" aria-hidden="true"></b-icon>
                                Thông tin cá nhân
                            </a>
                            <div  v-if="$store.state.client" class="dropdown-divider"></div>
                            <a v-if="$store.state.client && permissions('client_update')" href="#/setting/profile/client" class="dropdown-item">
                                <i class="fa fa-cube"></i>
                                {{$store.state.client.name}}
                            </a>
                            <a v-if="$store.state.client && permissions('staff_index')" href="#/setting/profile/staff" class="dropdown-item">
                                <i class="fa fa-users"></i>
                                Nhân viên
                            </a>
                            <!--                            <div class="dropdown-divider"></div>
                                                        <a href="#" class="dropdown-item">
                                                            <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon>
                                                            Cập nhật hệ thống
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <em class='fa fa-life-ring'></em>
                                                            Hỗ trợ báo lỗi
                                                        </a>
                                                        <a href="#" class="dropdown-item">
                                                            <em class='fa fa-book'></em>
                                                            Hướng dẫn sử dụng
                                                        </a>-->
                            <div class="dropdown-divider"></div>
                            <a href="javascript:;" @click="logout" class="dropdown-item dropdown-footer">
                                <b-icon icon="power" aria-hidden="true"></b-icon> Đăng xuất
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import role from '@/mixins/Role';
    export default {
        mixins: [role],
        data() {
            return {
                appName: process.env.VUE_APP_NAME
            };
        },
        methods: {
            hasRole(href) {
                if (!href.startsWith("#/manager/setting")) {
                    return true;
                }
                let action = href.split("/").slice(-1)[0];
                return this.hasIndicator(action);
            },
            logout: function () {
                location.href = '#/login';
                this.$store.dispatch('logout');
            },
            getAvatar: function () {
                let avatar = '';
                let nameParts = this.$store.state.user.name.split(' ').map(part => part.trim()).filter(part => part);
                if (nameParts.length > 1) {
                    for (let i = 0; i < 2; i++) {
                        avatar += nameParts[i].substr(0, 1);
                    }
                } else {
                    avatar += nameParts[0].substr(0, nameParts[0].length > 1 ? 2 : 1);
                }
                return avatar.toUpperCase();
            },
            update() {
                window.location.reload();
            }
        },
        mounted: function () {
        }
    };
</script>
