import Vue from 'vue';
import 'core-js';
import App from './App.vue';
import router from './router';
import store from './store';
import params from './params';
import utils from './utils';
import $ from 'jquery';
import axios from 'axios';
import {bus} from './bus';
import VueNativeNotification from 'vue-native-notification';
import VueSkeletonLoading from 'vue-skeleton-loading';
import numFormat from 'vue-filter-number-format';
import numeral from 'numeral';
import moment from 'vue-moment';
import form from './components/form';
import grid from './components/grid';
import filter from './components/filter';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import "./registerServiceWorker.js";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueClipboard from 'vue-clipboard2';
import ReadMore from './components/vue-read-more';

if (process.env.NODE_ENV !== 'production') {
    localStorage.debug = null;
}

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ReadMore);
Vue.use(grid);
Vue.use(form);
Vue.use(filter);
Vue.use(moment);
Vue.use(VueSkeletonLoading);
Vue.use(VueSweetalert2);
Vue.filter('numFormat', numFormat(numeral));
Vue.use(VueHtmlToPaper);
Vue.use(VueClipboard);
Vue.use(VueNativeNotification, {
    requestOnNotify: true
});

//htpp request
const serviceUrl = process.env.VUE_APP_SERVICE_URL;
const service = axios.create({
    baseURL: serviceUrl,
    timeout: 120000
});
service.defaults.headers.common['Access-Control-Allow-Origin'] = ['*'];
service.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (!error.response) {
        Vue.swal({
            text: 'Không kết nối được với máy chủ!',
            icon: 'error'
        });
    } else if (error.response.status === 401) {
        service.defaults.headers.common['token'] = null;
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        location.reload();
    } else if (error.response.status === 500 || error.response.status === 404 || error.response.status === 403) {
        if (error.response.data.message) {
            Vue.swal({
                text: error.response.data.message,
                icon: 'error'
            });
        } else {
            Vue.swal({
                text: error.message,
                icon: 'error'
            });
        }
    }
    return Promise.reject(error);
});

Vue.prototype.$service = service;
Vue.prototype.$bus = bus;
Vue.prototype.$socket = null;
Vue.prototype.$params = params;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;

//Điều hướng
router.beforeEach(function (to, from, next) {
    const publicPages = ['/login', '/register', '/forgot', '/reset', '/use', "/s/"];
    let authRequired = true;
    publicPages.forEach(function (val) {
        if (to.path.startsWith(val)) {
            authRequired = false;
            return;
        }
    });
    if (authRequired) {
        if (!store.state.user) {
            return next({
                path: '/login',
                query: {redirect: to.fullPath}
            });
        }
    }
    next();
});

const mount = function () {
    $('#loading').remove();
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
};

let token = localStorage.getItem('token');
if (!token) {
    token = sessionStorage.getItem('token');
}
if (token) {
    store.commit('token', token);
    store.dispatch('login', {
        token: token,
    }).finally(() => {
        mount();
    });
} else {
    mount();
}