import FormInput from "./FormInput";
import FormDateTime from "./FormDateTime";
import FormSelect from "./FormSelect";
import FormSelectMultiple from "./FormSelectMultiple";
import FormTextarea from "./FormTextarea";
import InputDateTime from "./InputDateTime";
import FormCheckbox from "./FormCheckbox";
import FormArray from "./FormArray";
import FormCheckboxGroup from "./FormCheckboxGroup";
import TimeRangePicker from "./TimeRangePicker";
import FormEditor from "./FormEditor";
import FormEditorBasic from "./FormEditorBasic";
import FormRadioGroup from "./FormRadioGroup";
import FormCustomFields from "./FormCustomFields";
import FormInputSuggestion from "./FormInputSuggestion";
import FormFile from "./FormFile";
import FormGroupAddress from "./FormGroupAddress";
import FormInputCurrency from "./FormInputCurrency";

export default {
    install(Vue) {
        Vue.component('form-input', FormInput);
        Vue.component('form-date-time', FormDateTime);
        Vue.component('form-select', FormSelect);
        Vue.component('form-select-multiple', FormSelectMultiple);
        Vue.component('form-textarea', FormTextarea);
        Vue.component('form-input-date-time', InputDateTime);
        Vue.component('form-checkbox', FormCheckbox);
        Vue.component('form-checkbox-group', FormCheckboxGroup);
        Vue.component('form-array', FormArray);
        Vue.component('time-range-picker', TimeRangePicker);
        Vue.component('form-editor', FormEditor);
        Vue.component('form-editor-basic', FormEditorBasic);
        Vue.component('form-radio-group', FormRadioGroup);
        Vue.component('form-custom-fields', FormCustomFields);
        Vue.component('form-input-suggestion', FormInputSuggestion);
        Vue.component('form-file', FormFile);
        Vue.component('form-group-address', FormGroupAddress);
        Vue.component('form-input-currency', FormInputCurrency);
    }
}