import moment from 'moment';

export default {
    install(Vue) {
        Vue.filter('dateFormat', function (value) {
            return moment(value).format('DD/MM/YYYY');
        });
        Vue.filter('datetimeFormat', function (value) {
            return moment(value).format('HH:mm DD/MM/YYYY');
        });
        Vue.filter('datetimefullFormat', function (value) {
            return moment(value).format('HH:mm:ss DD/MM/YYYY');
        });
        Vue.filter('phoneDisplay', function (phone, show) {
            if (!phone) {
                return  '';
            }
            if (!show) {
                return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1****$3');
            }
            return phone;
        });
    }
}