<template>
    <div v-if="showLoading">
        <skeleton-loading>
            <row>
                <square-skeleton :boxProperties="{bottom: '10px', height: '30px'}"></square-skeleton>
            </row>
            <row>
                <square-skeleton :boxProperties="{bottom: '10px', height: '200px'}"></square-skeleton>
            </row>
        </skeleton-loading>
    </div>
    <b-form-group v-else :label="label"
                  :invalid-feedback="errorTxt"
                  :state="state">
        <b-form-file
            v-model="inputVal"
            :accept="accept"
            :state="state"
            @input="change"
            :multiple="multiple"
            plain
            >
        </b-form-file>
        <small class="form-text text-muted">{{placeholder}}</small>
    </b-form-group>
</template>

<script>
    export default {
        props: {
            showLoading: null,
            multiple: Boolean,
            service: String,
            model: Object,
            attribute: String,
            attributeFileName: String,
            attributeFileType: String,
            label: String,
            placeholder: String,
            errors: Object,
            accept: String,
            maxSize: {
                type: Number,
                default: 10 * 1024 * 1024,
            },
            dir: {
                type: String,
                default: "",
            },
            errorOnly: Boolean
        },
        data() {
            return {inputVal: null, state: null, errorTxt: ''}
        },
        methods: {
            change() {
                this.state = null;
                this.errorTxt = null;
                this.model[this.attribute] = null;
                if (this.inputVal && !this.multiple) {
                    if (this.accept && !this.accept.split(',').includes(this.inputVal.type)) {
                        this.state = false;
                        this.errorTxt = 'Invalid file type';
                    } else if (this.inputVal.size > this.maxSize) {
                        this.state = false;
                        this.errorTxt = 'Max file size exceeded';
                    } else if (this.service && this.service !== '') {
                        this.saveLoading = true;
                        const formData = new FormData();
                        formData.append('path', this.dir);
                        formData.append('uploaded_files[0]', this.inputVal);
                        this.$service.post(this.service, formData).then(response => {
                            if (response.data.hash) {
                                this.model[this.attribute] = response.data.hash;
                                this.model[this.attributeFileType] = response.data.type;
                            } else {
                                this.model[this.attribute] = response.data;
                                if (this.attributeFileName) {
                                    this.model[this.attributeFileName] = this.inputVal.name;
                                }
                                if (this.attributeFileType) {
                                    this.model[this.attributeFileType] = this.inputVal.type;
                                }
                            }
                        }).finally(() => {
                            this.showLoading = false;
                        });
                    } else {
                        this.model[this.attribute] = this.inputVal;
                    }
                } else if (this.inputVal && this.multiple && this.inputVal.length > 0) {
                    let flag = true;
                    if (this.accept) {
                        let accept = this.accept.split(',');
                        for (let i = 0; i < this.inputVal.length; i++) {
                            if (!accept.includes(this.inputVal[i].type)) {
                                flag = false;
                            }
                        }
                        if (flag == false) {
                            this.state = false;
                            this.errorTxt = 'Invalid file type';
                        }
                    }
                    if (this.maxSize) {
                        let flag = true;
                        for (let i = 0; i < this.inputVal.length; i++) {
                            if (this.maxSize < this.inputVal[i].size) {
                                flag = false;
                            }
                        }
                        if (flag == false) {
                            this.state = false;
                            this.errorTxt = 'Max file size exceeded';
                        }
                    }
                    if (this.service && this.service !== '' && flag == true) {
                        this.showLoading = true;
                        const formData = new FormData();

                        for (let i = 0; i < this.inputVal.length; i++) {
                            let file = this.inputVal[i];
                            formData.append('file', file);
                        }
                        this.$service.post(this.service, formData).then(response => {
                            if (response.data.length > 0 && response.data) {
                                this.model[this.attribute] = response.data;
                            }
                        }).finally(() => {
                            this.showLoading = false;
                        });
                    }
                }
            },
        },
        watch: {
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
    }
</script>