<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state">

        <b-form-checkbox @change="$emit('change')" v-model="inputVal" value="1" unchecked-value="0">
            {{ chkLabel }} <span v-if="required" class="text-danger">*</span>
        </b-form-checkbox>
    </b-form-group>
</template>

<script>
    export default {
        props: ['chkLabel', 'label', 'model', 'attribute', 'errors', 'errorOnly', 'required'],
        data() {
            return {inputVal: this.model[this.attribute] ? this.model[this.attribute] : null, state: null, errorTxt: ''}
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
    }
</script>