<template>
    <b-form-group :label="label"
                  :invalid-feedback="errorTxt"
                  :state="state">
        <template #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <b-form-checkbox-group
            v-model="inputVal"
            :state="state"
            :stacked='stacked' 
            @change="$emit('change')"
            :options="options">
        </b-form-checkbox-group>
    </b-form-group>
</template>

<script>
    export default {
        props: ['label', 'model', 'attribute', 'options', 'errors', 'errorOnly', 'stacked', 'required'],
        data: function () {
            return {inputVal: this.model[this.attribute] ? this.model[this.attribute] : null, state: null, errorTxt: ''}
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
    }
</script>
