<template>
    <b-form-group :label="label"
                  :invalid-feedback="errorTxt"
                  :state="state">
                  <vue-numeric v-model="inputVal"
                :type="type"
                :state="state"
                :placeholder="placeholder"
                :readonly="readonly"
                @keyup="$emit('keyup')" separator=","
                @focus="$emit('focus')"
                class="form-control"
                ></vue-numeric>
    </b-form-group>
</template>

<script>
    import VueNumeric from 'vue-numeric'
    export default {
        props: ['model', 'attribute', 'label', 'type', 'placeholder', 'errors', 'readonly', 'errorOnly'],
        data() {
            return {
                inputVal: this.model[this.attribute] ? this.model[this.attribute] : 0, 
                state: null, 
                errorTxt: ''
            }
        },
        components: {
          VueNumeric
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val.length) {
                    val.forEach(v => {
                        if (v.param === this.attribute) {
                            this.state = false;
                            this.errorTxt = v.msg;
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
    }
</script>