<template>
    <div class="row groupAddress">
        <b-form-group class="col-md-4" label="Tỉnh/Thành phố" :invalid-feedback="errorProvinceTxt" :state="provinceState">
            <template v-if="provinceRequired" #label>
                Tỉnh/Thành phố <span v-if="provinceRequired" class="text-danger">*</span>
            </template>
            <multiselect
                v-model="form.province"
                placeholder="Chọn Tỉnh/Thành phố"
                :options="provinceOptions"
                label="name" track-by="id"
                selectLabel="" deselectLabel="" selectedLabel="Đã chọn"
                :disabled="provinceDisabled" :class="errorProvinceTxt ? 'is-invalid' : ''"
                @input="onInputProvince">
                <template v-slot:noResult>
                    Không tìm thấy kết quả nào
                </template>
                <template v-slot:noOptions>
                    Không tìm thấy kết quả nào
                </template>
            </multiselect>
        </b-form-group>
        <b-form-group class="col-md-4" label="Quận/Huyện" :invalid-feedback="errorDistrictTxt" :state="districtState">
            <template v-if="districtRequired" #label>
                Quận/Huyện <span v-if="districtRequired" class="text-danger">*</span>
            </template>
            <multiselect
                v-model="form.district"
                placeholder="Chọn Quận/Huyện"
                :options="districtOptions"
                label="name" track-by="id"  :class="errorDistrictTxt ? 'is-invalid' : ''"
                selectLabel="" deselectLabel="" selectedLabel="Đã chọn"
                :disabled="form.province === null || districtDisabled"
                @input="onInputDistrict">
                <template v-slot:noResult>
                    Không tìm thấy kết quả nào
                </template>
                <template v-slot:noOptions>
                    Không tìm thấy kết quả nào
                </template>
            </multiselect>
        </b-form-group>
        <b-form-group class="col-md-4" label="Xã/Phường/Thị trấn" :invalid-feedback="errorWardTxt" :state="wardState">
            <template v-if="wardRequired" #label>
                Xã/Phường/Thị trấn <span v-if="wardRequired" class="text-danger">*</span>
            </template>
            <multiselect
                v-model="form.ward"
                placeholder="Chọn Xã/Phường/Thị trấn"
                :options="wardOptions"
                label="name" track-by="id"  :class="errorWardTxt ? 'is-invalid' : ''"
                selectLabel="" deselectLabel="" selectedLabel="Đã chọn"
                :disabled="form.province === null || form.district === null || wardDisabled">
                <template v-slot:noResult>
                    Không tìm thấy kết quả nào
                </template>
                <template v-slot:noOptions>
                    Không tìm thấy kết quả nào
                </template>
            </multiselect>
        </b-form-group>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import 'vue-multiselect/dist/vue-multiselect.min.css';

    export default {
        components: {
            Multiselect
        },
        props: {
            model: Object,
            provinceAttribute: {
                type: String,
                default: 'province_id'
            },
            provinceDisabled: {
                type: Boolean,
                default: false
            },
            districtAttribute: {
                type: String,
                default: 'district_id'
            },
            districtDisabled: {
                type: Boolean,
                default: false
            },
            wardAttribute: {
                type: String,
                default: 'ward_id'
            },
            wardDisabled: {
                type: Boolean,
                default: false
            },
            provinceRequired: {
                type: Boolean,
                default: false
            },
            districtRequired: {
                type: Boolean,
                default: false
            },
            wardRequired: {
                type: Boolean,
                default: false
            },
            errors: Object,
        },
        data() {
            return {
                provinceOptions: [],
                districtOptions: [],
                wardOptions: [],
                form: {
                    province: null,
                    district: null,
                    ward: null,
                },
                provinceState: null,
                districtState: null,
                wardState: null,
                errorProvinceTxt: '',
                errorDistrictTxt: '',
                errorWardTxt: '',
            }
        },
        watch: {
            model: {
                async handler(val) {
                    this.provinceState = null
                    this.districtState = null
                    this.wardState = null
                    this.errorProvinceTxt = ''
                    this.errorDistrictTxt = ''
                    this.errorWardTxt = ''
                    if (
                            val[this.provinceAttribute] != null
                            && val[this.districtAttribute] != null
                            && val[this.wardAttribute] != null
                            ) {
                        await this.getDistricts(val[this.provinceAttribute]);
                        await this.getWards(val[this.provinceAttribute], val[this.districtAttribute]);
                        this.loadSelected();
                    } else if (
                            val[this.provinceAttribute] == null
                            && val[this.districtAttribute] == null
                            && val[this.wardAttribute] == null
                            ) {
                        this.form.province = null;
                        this.form.district = null;
                        this.form.ward = null;
                    }
                },
                deep: true
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        switch (key) {
                            case this.provinceAttribute:
                                this.provinceState = false;
                                this.errorProvinceTxt = val[key].join(";");
                                break

                            case this.districtAttribute:
                                this.districtState = false;
                                this.errorDistrictTxt = val[key].join(";");
                                break

                            case this.wardAttribute:
                                this.wardState = false;
                                this.errorWardTxt = val[key].join(";")
                                break
                            default:
                                break;
                        }
                    });
                }
                this.provinceState = !hasErr
                this.districtState = !hasErr
                this.wardState = !hasErr
            },
            'form.province'(value) {
                this.model[this.provinceAttribute] = value ? value.id : null
            },
            'form.district'(value) {
                this.model[this.districtAttribute] = value ? value.id : null
            },
            'form.ward'(value) {
                this.model[this.wardAttribute] = value ? value.id : null
            },
        },
        methods: {
            async getProvinces() {
                await this.$service
                        .get('/province/get-province')
                        .then(response => {
                            this.provinceOptions = response.data
                        })
            },
            async getDistricts(provinceCode) {
                if (provinceCode) {
                    await this.$service
                            .get('/province/get-district?pid=' + provinceCode.toString().padStart(2, '0'))
                            .then(response => {
                                this.districtOptions = response.data
                            })
                }
            },
            async getWards(provinceCode, districtCode) {
                if (provinceCode && districtCode) {
                    await this.$service.get('/province/get-ward?pid=' + provinceCode.toString().padStart(2, '0')
                            + '&did=' + districtCode.toString().padStart(3, '0'))
                            .then(response => {
                                this.wardOptions = response.data
                            })
                }
            },
            onInputProvince() {
                this.form.district = null
                this.form.ward = null

                this.model[this.districtAttribute] = null
                this.model[this.wardAttribute] = null

                if (this.form.province)
                    this.getDistricts(this.form.province.id)
            },
            onInputDistrict() {
                this.form.ward = null
                this.model[this.wardAttribute] = null

                if (this.form.province && this.form.district)
                    this.getWards(this.form.province.id, this.form.district.id)
            },
            loadSelected() {
                this.form.province = this.provinceOptions.find(item => item.id == this.model[this.provinceAttribute]);
                this.form.district = this.districtOptions.find(item => item.id == this.model[this.districtAttribute]);
                this.form.ward = this.wardOptions.find(item => item.id == this.model[this.wardAttribute]);

                this.isDisabledDistrict = false;
                this.isDisabledWard = false;
            },
        },
        mounted() {
            Promise.all([
                this.getProvinces(),
                this.getDistricts(this.model[this.provinceAttribute]),
                this.getWards(this.model[this.provinceAttribute], this.model[this.districtAttribute])
            ])
                    .then(() => {
                        this.loadSelected()
                    })

        },
    }
</script>

<style>
    .multiselect__tags {
        border: 2px solid #ced4da
    }
</style>
