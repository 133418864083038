export default {
    getKeyword: function (str) {
        str = str.trim();
        str = this.removeDiacritical(str);
        str = this.removeSpecialChar(str);
        str = this.stripMultiSpace(str);
        return str.toLowerCase();
    },
    createAlias: function (str) {
        str = str.toString().trim();
        str = this.removeDiacritical(str);
        str = this.removeSpecialChar(str);
        str = this.stripMultiSpace(str);
        str = str.replace(/\s/g, '-');
        return str.toLowerCase();
    },
    createCode: function (str) {
        str = str.trim();
        str = this.removeDiacritical(str);
        str = this.removeSpecialChar(str);
        str = str.replace(/\s/g, '-');
        return str.toLowerCase();
    },
    removeDiacritical: function (str) {
        str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
        str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
        str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
        str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
        str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
        str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
        str = str.replace(/(đ)/g, 'd');
        str = str.replace(/(À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ)/g, 'A');
        str = str.replace(/(È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ)/g, 'E');
        str = str.replace(/(Ì|Í|Ị|Ỉ|Ĩ)/g, 'I');
        str = str.replace(/(Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ)/g, 'O');
        str = str.replace(/(Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ)/g, 'U');
        str = str.replace(/(Ỳ|Ý|Ỵ|Ỷ|Ỹ)/g, 'Y');
        str = str.replace(/(Đ)/g, 'D');
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return str;
    },
    removeSpecialChar: function (str) {
        return str.replace(/[^A-Za-z0-9\-\s]/g, '');
    },
    stripMultiSpace: function (str) {
        return str.replace(/\s+/g, ' ');
    },
    getParamCount: (str) => {
        const re = /\{p\}/g;
        return ((str || '').match(re) || []).length;
    },
    timeAgoNaive: (time) => {
        let timeparts = [
            {name: 'năm', div: 31536000000, mod: 10000},
            {name: 'ngày', div: 86400000, mod: 365},
            {name: 'giờ', div: 3600000, mod: 24},
            {name: 'phút', div: 60000, mod: 60},
            {name: 'giây', div: 1000, mod: 60}
        ];

        let i = 0, l = timeparts.length, calc, values = [];
        let interval = new Date().getTime() - time;
        while (i < l) {
            calc = Math.floor(interval / timeparts[i].div) % timeparts[i].mod;
            if (calc) {
                values.push(calc + ' ' + timeparts[i].name);
            }
            i += 1;
        }
        if (values.length === 0) {
            values.push('0 giây');
        }
        return values.join(' ') + ' trước';
    },
    readMoneyforvietnamese: function (SoTien) {
        let Tien = new Array("", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ");
        let lan = 0;
        let i = 0;
        let so = 0;
        let KetQua = "";
        let tmp = "";
        let ViTri = new Array();
        if (SoTien < 0)
            return "Số tiền âm !";
        if (SoTien === 0)
            return "Không đồng !";
        if (SoTien > 0)
        {
            so = SoTien;
        } else
        {
            so = -SoTien;
        }
        if (SoTien > 8999999999999999)
        {
            //SoTien = 0;
            return "Số quá lớn!";
        }
        ViTri[5] = Math.floor(so / 1000000000000000);
        if (isNaN(ViTri[5]))
            ViTri[5] = "0";
        so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
        ViTri[4] = Math.floor(so / 1000000000000);
        if (isNaN(ViTri[4]))
            ViTri[4] = "0";
        so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
        ViTri[3] = Math.floor(so / 1000000000);
        if (isNaN(ViTri[3]))
            ViTri[3] = "0";
        so = so - parseFloat(ViTri[3].toString()) * 1000000000;
        ViTri[2] = parseInt(so / 1000000);
        if (isNaN(ViTri[2]))
            ViTri[2] = "0";
        ViTri[1] = parseInt((so % 1000000) / 1000);
        if (isNaN(ViTri[1]))
            ViTri[1] = "0";
        ViTri[0] = parseInt(so % 1000);
        if (isNaN(ViTri[0]))
            ViTri[0] = "0";
        if (ViTri[5] > 0)
        {
            lan = 5;
        } else if (ViTri[4] > 0)
        {
            lan = 4;
        } else if (ViTri[3] > 0)
        {
            lan = 3;
        } else if (ViTri[2] > 0)
        {
            lan = 2;
        } else if (ViTri[1] > 0) {
            lan = 1;
        } else {
            lan = 0;
        }
        for (i = lan; i >= 0; i--) {
            tmp = this.docSo3ChuSo(ViTri[i]);
            KetQua += tmp;
            if (ViTri[i] > 0)
                KetQua += Tien[i];
            if ((i > 0) && (tmp.length > 0))
                KetQua += ',';//&& (!string.IsNullOrEmpty(tmp))
        }
        if (KetQua.substring(KetQua.length - 1) === ',') {
            KetQua = KetQua.substring(0, KetQua.length - 1);
        }
        KetQua = KetQua.substring(1, 2).toUpperCase() + KetQua.substring(2);
        return KetQua;
    },
    docSo3ChuSo: function (baso) {
        let ChuSo = new Array(" không ", " một ", " hai ", " ba ", " bốn ", " năm ", " sáu ", " bảy ", " tám ", " chín ");
        let tram;
        let chuc;
        let donvi;
        let KetQua = "";
        tram = parseInt(baso / 100);
        chuc = parseInt((baso % 100) / 10);
        donvi = baso % 10;
        if (tram === 0 && chuc === 0 && donvi === 0)
            return "";
        if (tram !== 0) {
            KetQua += ChuSo[tram] + " trăm ";
            if ((chuc === 0) && (donvi !== 0))
                KetQua += " linh ";
        }
        if ((chuc !== 0) && (chuc !== 1)) {
            KetQua += ChuSo[chuc] + " mươi";
            if ((chuc === 0) && (donvi !== 0))
                KetQua = KetQua + " linh ";
        }
        if (chuc === 1) {
            KetQua += " mười ";
        }
        switch (donvi) {
            case 1:
                if ((chuc !== 0) && (chuc !== 1)) {
                    KetQua += " mốt ";
                } else {
                    KetQua += ChuSo[donvi];
                }
                break;
            case 5:
                if (chuc === 0)
                {
                    KetQua += ChuSo[donvi];
                } else
                {
                    KetQua += " lăm ";
                }
                break;
            default:
                if (donvi !== 0)
                {
                    KetQua += ChuSo[donvi];
                }
                break;
        }
        return KetQua;
    },
    formatNumber: function (value) {
        if (!value) {
            return 0;
        }
        return value.toLocaleString(
                'en-IN'
                );
    },
    setWithExpiry: function (key, value, ttl) {
        let now = new Date();
        let item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry: function (key) {
        let itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        let item = JSON.parse(itemStr);
        let now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
};