import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
export default new Router({
    routes: [
        {path: '/index', component: () => import('./views/Index')},
        {
            path: '/setting', component: () => import('./views/setting/Setting'), children: [
                {path: '', redirect: '/setting/parameter'},
                {path: 'index', redirect: '/setting/parameter'},
                {path: 'parameter', redirect: '/setting/parameter/app-version'},
                {path: 'etc', props: true, component: () => import('./views/setting/parameter/Etc')},
                {path: 'parameter/:type', props: true, component: () => import('./views/setting/parameter/Parameter')},
                {path: 'profile', component: () => import('./views/setting/account-info/Profile')},
                {path: 'device-token', component: () => import('./views/setting/account-info/DeviceToken')},
                {path: 'staff', component: () => import('./views/setting/roles-and-users/Staff')},
                {path: 'action-define', component: () => import('./views/setting/roles-and-users/Define')},
                {path: 'roles', component: () => import('./views/setting/roles-and-users/Roles')},
                {path: 'roles/:id', props: true, component: () => import('./views/setting/roles-and-users/Role')},
                {path: 'notify', component: () => import('./views/setting/Notify')},
                {path: 'email', component: () => import('./views/setting/communication/email/Email')},
                {path: 'client', component: () => import('./views/setting/client/Client')},
                {path: 'dashboard', component: () => import('./views/setting/client/Dashboard')},
                {path: 'staff-activity', component: () => import('./views/setting/history/StaffActivity')},
                {path: 'emailoutbox', component: () => import('./views/setting/history/Emailoutbox')},
                {path: 'notify', component: () => import('./views/setting/history/Notify')},
                {path: 'province', component: () => import('./views/setting/local/Province')},
            ]
        },
        {
            path: '/page', component: () => import('./views/page/Setting'), children: [
                {path: '', redirect: '/page/index'},
                {path: 'index', redirect: '/page/profile/staff'},
                {path: 'profile/staff', component: () => import('./views/page/account-info/Staff')},
            ]
        },
        {
            path: '/manager', component: () => import('./views/manager/Manager'), children: [
                {path: '', redirect: '/manager/dashboard'},
                {path: 'index', redirect: '/manager/dashboard'},
                {path: 'dashboard', component: () => import('./views/manager/Dashboard')},
                {path: 'dashboard-fixed', component: () => import('./views/manager/DashboardFixed')},
                {path: 'report', component: () => import('./views/manager/Report')},
                {path: 'dashboard/indicator01', component: () => import('./views/manager/dashboard/Indicator01')},
                {path: 'dashboard/indicator02', component: () => import('./views/manager/dashboard/Indicator02')},
                {path: 'dashboard/indicator03', component: () => import('./views/manager/dashboard/Indicator03')},
                {path: 'dashboard/indicator04', component: () => import('./views/manager/dashboard/Indicator04')},
                {path: 'dashboard/indicator05', component: () => import('./views/manager/dashboard/Indicator05')},
                {path: 'dashboard/indicator07', component: () => import('./views/manager/dashboard/Indicator07')},
                {path: 'dashboard/indicator08', component: () => import('./views/manager/dashboard/Indicator08')},
                {path: 'dashboard/indicator09', component: () => import('./views/manager/dashboard/Indicator09')},
                {path: 'dashboard/indicator12', component: () => import('./views/manager/dashboard/Indicator12')},
                {path: 'dashboard/indicator06', component: () => import('./views/manager/dashboard/Indicator06')},
                {path: 'dashboard/indicator14', component: () => import('./views/manager/dashboard/Indicator14')},
                {path: 'dashboard/indicator15', component: () => import('./views/manager/dashboard/Indicator15')},
                {path: 'dashboard/indicator16', component: () => import('./views/manager/dashboard/Indicator16')},
                {path: 'dashboard/indicator25', component: () => import('./views/manager/dashboard/Indicator25')},
                {path: 'dashboard/indicator18', component: () => import('./views/manager/dashboard/Indicator18')},
                {path: 'dashboard/indicator17', component: () => import('./views/manager/dashboard/Indicator17')},
                {path: 'dashboard/indicator21', component: () => import('./views/manager/dashboard/Indicator21')},
                {path: 'dashboard/indicator24', component: () => import('./views/manager/dashboard/Indicator24')},
                {path: 'dashboard/indicator23', component: () => import('./views/manager/dashboard/Indicator23')},
                {path: 'dashboard/indicator10', component: () => import('./views/manager/dashboard/Indicator10.vue')},
                {path: 'dashboard/indicator11', component: () => import('./views/manager/dashboard/Indicator11.vue')},
                {path: 'dashboard/indicator29', component: () => import('./views/manager/dashboard/Indicator29.vue')},
                {path: 'dashboard/indicator28', component: () => import('./views/manager/dashboard/Indicator28.vue')},
                {path: 'dashboard/indicator27', component: () => import('./views/manager/dashboard/Indicator27.vue')},
                {
                    path: 'setting', component: () => import('./views/manager/Setting'), children: [
                        {path: '', redirect: '/manager/setting/patient'},
                        {path: 'index', redirect: '/manager/setting/patient'},
                        {path: 'patient', component: () => import('./views/manager/patient/Patient')},
                        {path: 'patient-relative', component: () => import('./views/manager/patient/PatientRelative')},
                        {path: 'patient-life', component: () => import('./views/manager/patient/PatientLife')},
                        {path: 'org', component: () => import('./views/manager/org/Org')},
                        {path: 'org-cso', component: () => import('./views/manager/org/OrgCso')},
                        {path: 'org-multiple', component: () => import('./views/manager/org-multiple/OrgMultiple')},
                        {path: 'indicator-manual', component: () => import('./views/manager/indicator/Manual')},
                        {path: 'kpi', redirect: '/manager/setting/kpi/index'},
                        {path: 'kpi/index', component: () => import('./views/manager/kpi/Setup')},
                        {path: 'kpi/:id', props: true, component: () => import('./views/manager/kpi/Setup')},
                        {path: 'kpi/view/:id', props: true, component: () => import('./views/manager/kpi/View')},
                        {path: 'edu-patient', props: true, component: () => import('./views/manager/edu-patient/EduPatient')},
                        {path: 'edu-phcn', props: true, component: () => import('./views/manager/edu-patient/edu-phcn/EduPhcn')},
                        {path: 'edu-care', props: true, component: () => import('./views/manager/edu-patient/edu-care/EduCare')},
                        {path: 'edu-gender', props: true, component: () => import('./views/manager/edu-patient/edu-gender/EduGender')},
                        {path: 'edu-event', props: true, component: () => import('./views/manager/edu-patient/edu-event/EduEvent')},
                        {path: 'edu-other', props: true, component: () => import('./views/manager/edu-patient/edu-other/EduOther')},
                        {path: 'patient-phcn', props: true, component: () => import('./views/manager/patient-phcn/PatientPhcn')},
                        {path: 'patient-care', props: true, component: () => import('./views/manager/patient-care/PatientCare')},
                        {path: 'patient-relative-train', props: true, component: () => import('./views/manager/patient-relative-train/PatientRelativeTrain')},
                        {path: 'patient-mentality', props: true, component: () => import('./views/manager/patient-mentality/PatientMentality')},
                        {path: 'patient-integrate', props: true, component: () => import('./views/manager/patient-integrate/PatientIntegrate')},
                        {path: 'patient-other', component: () => import('./views/manager/patient-other/PatientOther')},
                    ]
                },
                {
                    path: 'kpi', component: () => import('./views/manager/Kpi'), children: [
                        {path: '', redirect: '/manager/kpi/index'},
                        {path: 'index', component: () => import('./views/manager/kpi/Setup')},
                        {path: ':id', props: true, component: () => import('./views/manager/kpi/Setup')},
                        {path: 'view/:id', props: true, component: () => import('./views/manager/kpi/View')},
                    ]
                },
            ]
        },
        {path: '/use/:token', component: () => import('./views/UseUser'), props: true},
        {path: '/login', component: () => import('./views/auth/Login')},
        {path: '/forgot', component: () => import('./views/auth/Forgot')},
        {path: '/reset/:token', component: () => import('./views/auth/Reset'), props: true},
        {path: '*', redirect: '/index'},
        {path: '/', redirect: '/index'}
    ],
})
