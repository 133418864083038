<template>
    <b-form-group :label="label"
                  :invalid-feedback="errorTxt"
                  :state="state">
        <template #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <vue-bootstrap-typeahead 
            v-model="inputVal" 
            :data="options" 
            :size="`sm`" 
            :minMatchingChars="0" 
            :state="state" 
            :placeholder="placeholder" 
            @hit="inputVal = $event"
            @input="$emit('change')" 
            ref="typeahead" />
    </b-form-group>
</template>

<script>
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

    export default {
        props: ['model', 'attribute', 'label', 'placeholder', 'errors', 'readonly', 'errorOnly', "options", 'required'],
        components: {VueBootstrapTypeahead},
        data() {
            return {
                inputVal: this.model[this.attribute] ? this.model[this.attribute] : null,
                state: null,
                errorTxt: ''
            };
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                    this.$refs.typeahead.inputValue = this.inputVal;
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        mounted() {
            if (this.model && this.model[this.attribute] && this.model[this.attribute] != null) {
                this.$refs.typeahead.inputValue = this.model[this.attribute];
            }
        },
    }
</script>