<template>
    <footer class="main-footer">
        <!--<strong>Chemonics</strong>-->
        <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> {{appVersion}} 
            <span v-if="$store.state.gitVersion">- <b>Git</b> {{$store.state.gitVersion}}</span>
        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                appVersion: process.env.VUE_APP_VERSION
            };
        }
    }
</script>