<template>
    <div v-if="customFields.length > 0">
        <h5 v-if="!disabledTitle">Thông tin tuỳ biến</h5>
        <div class="alert alert-danger" v-if="errorTxt">{{errorTxt}}</div>
        <div class="row" >
            <div :class="col" v-for="field in customFields" :key="field._id">
                <form-input v-if="field.inputType === 'text'" :label="field.label" :required="field.required" :model="customFieldsModel" :attribute="field.key" :errors="fieldErrors" :error-only="true"></form-input>
                <form-input v-if="field.inputType === 'number'" type="number" :label="field.label" :required="field.required" :model="customFieldsModel"
                    :attribute="field.key" :errors="fieldErrors" :error-only="true"></form-input>
                <form-select v-if="field.inputType === 'one'" :errors="fieldErrors" :label="`${field.label} ${field.required ? '*':''}`" :model="customFieldsModel" :attribute="field.key" :options="getOptions(field)" :error-only="true"></form-select>
                <form-checkbox-group v-if="field.inputType === 'multiple'" :errors="fieldErrors" :model="customFieldsModel" :attribute="field.key" :label="`${field.label} ${field.required ? '*':''}`" :options="getOptions(field)" :error-only="true"></form-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            model: Object,
            attribute: {
                type: String,
                default: 'customFields'
            },
            disabledTitle: {
                type: Boolean,
                default: true
            },
            col: {
                type: String,
                default: 'col-12'
            },
            customFields: Array,
            type: String,
            errors: Object
        },
        data() {
            return {
                customFieldsModel: {},
                fieldErrors: {},
                errorTxt: null
            };
        },
        watch: {
            customFieldsModel: {
                handler() {
                    let model = [];
                    let errors = {};
                    this.customFields.forEach(field => {
                        if (this.customFieldsModel[field.key]) {
                            let val = [];
                            if (field.inputType === 'multiple') {
                                val = this.customFieldsModel[field.key].filter(v => field.inputOptions.includes(v));
                            } else if (field.inputType === 'one') {
                                val = [field.inputOptions.find(o => o === this.customFieldsModel[field.key])];
                            } else {
                                val = [this.customFieldsModel[field.key]];
                            }
                            if (val && val.length) {
                                model.push({
                                    key: field.key,
                                    val: val
                                });
                            } else {
                                if (field.required) {
                                    errors[field.key] = ["Bắt buộc nhập"];
                                }
                            }
                        } else {
                            if (field.required) {
                                errors[field.key] = ["Bắt buộc nhập"];
                            }
                        }
                    });
                    if (Object.keys(errors).length > 0) {
                        this.fieldErrors = errors;
                        this.model[this.attribute] = null;
                    } else {
                        this.model[this.attribute] = model;
                    }
                }, deep: true
            },
            errors(val) {
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.errorTxt = val[key].join(";");
                        }
                    });
                }
            }
        },
        methods: {
            getOptions(field) {
                let options = [];
                if (field.inputType === 'one' || field.inputType === 'multiple') {
                    if (field.inputType === 'one') {
                        options.push({value: null, text: "Chưa rõ"});
                    }
                    field.inputOptions.forEach(function (option) {
                        options.push({value: option, text: option});
                    });
                }
                return options;
            },
            loadModel() {
                let model = {};
                this.customFields.forEach(field => {
                    let val = null;
                    let customField;
                    if (this.model[this.attribute]) {
                        customField = this.model[this.attribute].find(v => v.key === field.key);
                    }
                    if (customField) {
                        val = field.inputType === 'multiple' ? customField.val : customField.val[0];
                    }
                    model[field.key] = val;
                });
                this.customFieldsModel = model;
            }
        },
        mounted() {
            this.loadModel();
        }
    }
</script>
