<template>
    <date-picker v-model="inputVal" :config="options" :placeholder="placeholder"></date-picker>
</template>

<script>
    import moment from 'moment';
    import VueBootstrapDatetimePicker from 'vue-bootstrap-datetimepicker';

    export default {
        props: {
            model: Object,
            attribute: String,
            label: String,
            placeholder: String,
            readonly: Boolean,
            format: {
                type: String,
                default: 'D MMMM YYYY'
            }
        },
        components: {
            'date-picker': VueBootstrapDatetimePicker
        },
        data() {
            return {
                inputVal: this.model[this.attribute] ? moment(this.model[this.attribute], 'x').format(this.format) : null,
                state: null,
                errorTxt: '',
                options: {
                    format: this.format,
                    useCurrent: false
                }
            }
        },
        watch: {
            format() {
                this.options = {
                    format: this.format,
                    useCurrent: false
                }
            },
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute]) {
                        this.inputVal = moment(val[this.attribute], 'x').format(this.format);
                    } else {
                        this.inputVal = null;
                    }
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                try {
                    let time = moment(val, this.format);
                    this.model[this.attribute] = moment(time).valueOf();
                } catch (e) {
                    this.model[this.attribute] = 0;
                }
            }
        },
    }
</script>