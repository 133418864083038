<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state" class='input-group-sm'>
        <template v-if="required" #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <multiselect v-model="inputVal" track-by="value" label="text"
                     :placeholder="placeHolder ? placeHolder : 'Chọn thông tin'"
                     :close-on-select="closeOnSelect" 
                     :options="options" :multiple="multiple" @input="onInput"
                     select-label="Chọn" deselect-label="Xoá" selected-label="Đã chọn">
            <template v-slot:noResult>
                Không tìm thấy kết quả nào
            </template>
            <template v-slot:noOptions>
                Không tìm thấy kết quả nào
            </template>
        </multiselect>
    </b-form-group>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import 'vue-multiselect/dist/vue-multiselect.min.css';

    export default {
        props: ['model', 'attribute', 'label', 'options', 'errors', 'readonly', 'errorOnly', 'placeHolder', 'closeOnSelect', 'required'],
        components: {Multiselect},
        data() {
            return {
                multiple: true,
                inputVal: null,
                state: null,
                errorTxt: '',
                changeTrigger: false
            };
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute] && val[this.attribute] !== null) {
                        if (this.changeTrigger) {
                            this.loadSelected();
                        }
                        this.changeTrigger = true;
                    } else {
                        this.inputVal = null;
                    }
                }, deep: true
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            onInput() {
                let val = this.inputVal;
                this.changeTrigger = false;
                if (val === null) {
                    this.model[this.attribute] = val;
                } else {
                    this.model[this.attribute] = val.map(s => s.value);
                }
                this.$emit('change');
            },
            loadSelected() {
                if (this.model[this.attribute]) {
                    let ids;
                    if (this.multiple) {
                        ids = this.model[this.attribute];
                    } else {
                        ids = [this.model[this.attribute]];
                    }
                    if (ids) {
                        this.inputVal = [];
                        for (let i in this.options) {
                            let option = this.options[i];
                            if (ids.indexOf(option.value) !== -1) {
                                this.inputVal.push(option);
                            }
                        }
                    }
                }
            }
        },
        mounted() {
            this.inputVal = null;
            this.loadSelected();
        }
    }
</script>