<template>
    <b-form-group :label="label"
                  :invalid-feedback="errorTxt"
                  :state="state">
        <template v-if="required" #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <b-input-group v-if="append" :append="append" class="input-group-sm">
            <b-form-input class='form-control-sm'
                          v-model="inputVal"
                          :type="type"
                          :state="state"
                          :placeholder="placeholder"
                          :readonly="readonly"
                          :formatter="formatter"
                          :maxlength="maxlength"
                          @change="$emit('change')"
                          @keyup="$emit('keyup')">
            </b-form-input>
        </b-input-group>
        <b-form-input v-else class='form-control-sm'
                      v-model="inputVal"
                      :type="type"
                      :state="state"
                      :placeholder="placeholder"
                      :readonly="readonly"
                      :formatter="formatter"
                      :maxlength="maxlength"
                      @change="$emit('change')"
                      @keyup="$emit('keyup')">
        </b-form-input>
        <small v-if='desc' class="form-text text-muted">{{desc}}</small>
    </b-form-group>
</template>

<script>
    export default {
        props: [
            'model', 'attribute', 'label', 'type', 'placeholder', 'errors', 'readonly', 'errorOnly', "formatter",
            "maxlength", 'append', 'required', 'desc'
        ],
        data() {
            return {inputVal: this.model[this.attribute] ? this.model[this.attribute] : null, state: null, errorTxt: ''}
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
    }
</script>