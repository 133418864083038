<template>
    <b-button :disabled="loading" :variant="variant" :size="size" :type="type"
        v-b-tooltip.hover :title="title" @click="$emit('click')">
        <b-spinner v-if="loading" small></b-spinner>
        <i v-else-if="icon && icon.indexOf('fa ') > -1" :class="`${icon}`" ></i>
        <b-icon v-else :icon="icon" aria-hidden="true"></b-icon>
        {{content}}
    </b-button>
</template>

<script>
    export default {
        props: {
            loading: {type: Boolean, default: false},
            title: {type: String, required: false},
            type: {type: String, required: false, default: "button"},
            size: {type: String, required: false},
            variant: {type: String, required: false},
            icon: {type: String, required: true},
            content: {type: String, required: false},
        },
        data() {
            return {}
        },
    }
</script>