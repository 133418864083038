export default {
    chart: {
        colors: ['#005180', '#5796ba', '#aed4eb', '#2E93fA', '#66DA26', '#546E7A', '#0B9A18', '#FF9800', '#9933ff', '#09370E', '#FF0000'],
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val && val > 0 ? val : '';
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        }
    },
    const: {
        appVersion: {key: 'app-version', label: 'Phiên bản ứng dụng', group: 'Thư viện'},
        language: {key: 'language', label: 'Ngôn ngữ', group: 'Thư viện'},
        country: {key: 'country', label: 'Quốc gia', group: 'Thư viện'},
        race: {key: 'race', label: 'Dân tộc', group: 'Thư viện'},
    },
    etc: [
        {key: 'site-url', label: 'Tên miền ứng dụng (Backend)', type: 'TEXT'},
        {key: 'site-frontend-url', label: 'Tên miền cho người dùng (Frontend)', type: 'TEXT'},
        {key: 'site-media-url', label: 'Tên miền hình ảnh (Media)', type: 'TEXT'},
        {key: 'site-name', label: 'Tên ứng dụng', type: 'TEXT'},
        {key: 'site-color', label: 'Màu chính', type: 'TEXT', view: 'color'},
        {key: 'site-color-bold', label: 'Màu đậm', type: 'TEXT', view: 'color'},
        {key: 'site-color-hover', label: 'Màu hover', type: 'TEXT', view: 'color'},
    ],
    gender: [
        {value: null, text: "Chọn giới tính"},
        {value: 0, text: "Nam"},
        {value: 1, text: "Nữ"},
        {value: 2, text: "Khác"}
    ],
    indicator: {
        indicator01: {
            code: "",
            required: true,
            name: "Số người khuyết tật được dự án hỗ trợ trực tiếp",
            manual: false,
            report: ["quarter", "year"],
            role: ["patient", "patient-phcn", 'patient-care', 'patient-mentality','patient-integrate']
        },
        indicator02: {
            code: "(ES.4-9-CUST)",
            required: true,
            name: "Tỷ lệ người khuyết tật được dự án hỗ trợ trực tiếp cải thiện được chỉ số chất lượng cuộc sống",
            manual: false,
            report: ["year"],
            role: ["patient-life"]
        },
        indicator03: {
            code: "",
            required: true,
            name: "Số lượng người khuyết tật được can thiệp PHCN",
            manual: false,
            report: ["quarter"],
            role: ["patient", "patient-phcn"]
        },
        indicator04: {
            code: "(ES.4-10-CUST)",
            required: true,
            name: "Tỷ lệ người khuyết tật nhận dịch vụ PHCN cải thiện được chức năng sinh hoạt hàng ngày (ADL)",
            manual: false,
            report: ["year"],
            role: ["patient", "patient-phcn"]
        },
        indicator05: {
            code: "",
            required: true,
            name: "Số lượng người khuyết tật được chăm sóc",
            manual: false,
            report: ["quarter"],
            role: ["patient", "patient-care"]
        },
        indicator06: {
            code: "(ES.4-1-CUST)",
            required: true,
            name: "Tỷ lệ người khuyết tật nhận được dịch vụ chăm sóc và cải thiện các số đo kết quả chăm sóc",
            manual: false,
            report: ["year"],
            role: ["patient", "patient-care"]
        },
        indicator07: {
            code: "",
            required: true,
            name: "Số lượng người khuyết tật và người thân nhận được dịch vụ hỗ trợ và can thiệp tâm lý",
            manual: false,
            report: ["quarter"],
            role: ["patient", "patient-relative", 'patient-mentality']
        },
        indicator08: {
            code: "",
            required: true,
            name: "Tỷ lệ người khuyết tật và người thân nhận được hỗ trợ can thiệp và cải thiện được chỉ số về rối nhiễu tâm lý",
            manual: false,
            report: ["year"],
            role: ["patient", "patient-relative", 'patient-mentality']
        },
        indicator09: {
            code: "(ES.4-12-CUST)",
            required: true,
            name: "Số lượng người khuyết tật tham gia vào các hoạt động của mạng lưới các tổ chức (DPO)",
            manual: false,
            report: ["year"],
            role: ["patient", "edu-event"]
        },
        indicator10: {
            code: "(ES.4-13-CUST)",
            required: false,
            name: "Số người khuyết tật có hồ sơ được cập nhật trong hệ thống thông tin quản lý người khuyết tật",
            manual: true,
            detail: true,
            report: ["quarter"],
            role: ["indicator-manual"]
        },
        indicator11: {
            code: "",
            required: true,
            name: "Tỷ lệ nạn nhân chất da cam do Dự án hỗ trợ được cập nhật thông tin trên các hệ thống thông tin quản lý người khuyết tật tại địa phương",
            manual: true,
            detail: true,
            report: ["quarter"],
            role: ["indicator-manual"]
        },
        indicator12: {
            code: "",
            required: true,
            name: "Số lượng cán bộ phục hồi chức năng được đào tạo nâng cao năng lực chuyên môn",
            manual: false,
            report: ["quarter"],
            role: ["edu-patient", "edu-phcn"]
        },
        indicator13: {
            code: "(ES.4-8-CUST)",
            required: true,
            name: "Số cán bộ PHCN trên 10.000 dân với sự hỗ trợ của dự án",
            manual: false,
            detail: false,
            report: ["year"],
            role: ["indicator-manual"]
        },
        indicator13TuSo: {
            code: "",
            required: true,
            name: "Số cán bộ PHCN được dự án hỗ trợ trên địa bàn",
            manual: true,
            indicator: false,
            report: ["year"],
            role: ["indicator-manual"]
        },
        indicator13MauSo: {
            name: "Dân số trên địa bàn",
            manual: true,
            indicator: false,
            report: ["year"],
            role: ["indicator-manual"]
        },
        indicator14: {
            code: "",
            required: true,
            name: "Số lượng thành viên gia đình và người chăm sóc được đào tạo kỹ năng và nhận được hỗ trợ trong việc chăm sóc người khuyết tật",
            manual: false,
            report: ["quarter"],
            role: ["patient-relative", 'edu-patient', 'edu-care', 'patient-relative-train']
        },
        indicator15: {
            code: "(GNDR8)",
            required: false,
            name: "Số người được đào tạo với sự hỗ trợ của Chính phủ Mỹ để nâng cao kết quả phù hợp với bình đẳng giới hoặc trao quyền cho phụ nữ thông qua vai trò của họ trong các cơ sở hoặc tổ chức khu vực công hoặc tư nhân",
            manual: false,
            report: ["year"],
            role: ['patient', 'edu-patient', 'edu-phcn', 'edu-care', 'edu-gender', 'edu-event', 'edu-other']
        },
        indicator16: {
            code: "(GNDR-903-CUST)",
            required: false,
            name: "Số người được đào tạo về giảm thiểu bạo lực trên cơ sở giới",
            manual: false,
            report: ["quarter"],
            role: ['patient', 'edu-patient', 'edu-phcn', 'edu-care', 'edu-gender', 'edu-event', 'edu-other']
        },
        indicator17: {
            code: "(ES.4-11-CUST)",
            required: true,
            name: "Số lượng cơ sở phục hồi chức năng áp dụng phương thức tiếp cận đa chuyên ngành",
            manual: false,
            report: ["quarter", "year"],
            role: ["org-multiple"] //, "indicator-manual"
        },
        indicator18: {
            code: "(DR.4.2-2)",
            required: true,
            name: "Số lượng các tổ chức xã hội, các câu lạc bộ, hội người khuyết tật tham gia vận động chính sách hỗ trợ người khuyết tật",
            manual: false,
            report: ["year"],
            role: ["org-cso"]
        },
        indicator19: {
            code: "(ES.4-3)",
            required: true,
            name: "Số lượng các tổ chức cung cấp dịch vụ, quản lý các chương trình, dự án cho người khuyết tật do Dự án hỗ trợ được tăng cường năng lực",
            manual: false,
            detail: false,
            report: ["quarter"],
            role: ["org"]
        },
        indicator20: {
            code: "",
            required: true,
            name: "Số lượng các cơ quan, đơn vị sử dụng hệ thống quản lý số liệu và báo cáo các hoạt động Dự án",
            manual: false,
            detail: false,
            report: ["year"],
            role: [] //Tự tổng hợp
        },
        indicator21: {
            code: "(ES.4-2-CUST)",
            required: true,
            name: "Tỷ lệ công trình công cộng, cơ sở xây mới sẽ đạt tiêu chuẩn tiếp cận dành cho người khuyết tật",
            manual: false,
            report: ["year"],
            role: ['indicator-manual']
        },
        indicator21TuSo: {
            code: "",
            required: true,
            name: "Số công trình công cộng, cơ sở xây mới sẽ đạt tiêu chuẩn tiếp cận dành cho người khuyết tật trên địa bàn",
            manual: true,
            indicator: false,
            report: ["year"],
            role: ['indicator-manual']
        },
        indicator21MauSo: {
            code: "",
            required: true,
            name: "Số công trình công cộng, cơ sở xây mới trên địa bàn",
            manual: true,
            indicator: false,
            report: ["year"],
            role: ['indicator-manual']
        },
        indicator22: {
            code: "(DR.2.1-1-CUST)",
            required: true,
            name: "Danh mục các đề xuất và kế hoạch chính sách với sự hỗ trợ của Dự án",
            manual: true,
            detail: false,
            report: ["turn"],
            role: ['indicator-manual']
        },
        indicator23: {
            code: "",
            required: true,
            name: "Số lượng kế hoạch phát triển công tác phục hồi chức năng được xây dựng, phê duyệt và triển khai",
            manual: true,
            report: ["turn"],
            role: ['indicator-manual']
        },
        indicator24: {
            code: "",
            required: true,
            name: "Số lượng gói giá thành dịch vụ phục hồi chức năng cho tuyến tỉnh và huyện",
            manual: true,
            report: ["year"],
            role: ['indicator-manual']
        },
        indicator25: {
            code: "(ES.4-2)",
            required: false,
            name: "Số người cung cấp dịch vụ được đào tạo để phục vụ người yếu thế",
            report: ["quarter"],
            role: ["edu-patient", "edu-phcn", "edu-care"]
        },
        indicator26: {
            code: "(CBLD-10)",
            required: false,
            name: "Giá trị ($) của các nguồn lực phi tài trợ được huy động cho các ưu tiên phát triển của địa phương",
            manual: true,
            detail: false,
            report: ["quarter", "year"],
            role: ['indicator-manual']
        },
        //thêm 3 chỉ số mới
        indicator27: {
            code: "(ES.4-1)",
            required: false,
            name: "Số người yếu thế được hưởng lợi từ các dịch vụ xã hội do dự án hỗ trợ",
            manual: true,
            detail: true,
            report: ["quarter", "year"],
            role: ["patient-relative", "patient-phcn", 'patient-care', 'patient-mentality','patient-integrate']
        },
        indicator28: {
            code: "(ES.4-5-CUST)",
            required: false,
            name: "Số người chăm sóc hưởng lợi từ hỗ trợ của dự án",
            manual: true,
            detail: true,
            report: ["quarter", "year"],
            role: ["patient-relative", 'edu-care', 'patient-relative-train']
        },
        indicator29: {
            code: "(CBLD-9)",
            required: false,
            name: "Tỷ lệ tổ chức được hỗ trợ có cải thiện năng lực",
            manual: true,
            detail: true,
            report: ["year"],
            role: ["org"]
        }
    },
    client: {
        typeOptions: [
            {value: null, text: "Chọn loại đơn vị"},
            {value: "sub", text: "Nhà thầu phụ"},
            {value: "prime", text: "Nhà thầu chính"},
            {value: "tw", text: "Ban quản lý dự án TW"},
            {value: "usaid", text: "Nhà tài trợ"}
        ]
    },
    org: {
        typeInterventionOptions: [
            {value: null, text: "Chọn loại hình can thiệp"},
            {value: 1, text: "1. Tài trợ công cụ, thiết bị"},
            {value: 2, text: "2. Tài trợ nguồn tài chính"},
            {value: 3, text: "3. Nâng cao năng lực"},
        ],
        resultOptions: [
            {value: null, text: "Chọn kết quả cải thiện"},
            {value: 1, text: "1. Chưa được cải thiện năng lực"},
            {value: 2, text: "2. Đã được cải thiện năng lực"},
            {value: 3, text: "3. Không áp dụng"},
        ]
    },
    orgCso: {
        typeCsoOptions: [
            {value: null, text: "Chọn loại hình"},
            {value: 1, text: "1. Nhóm nữ quyền"},
            {value: 2, text: "2. Nhóm dân tộc thiểu số"},
            {value: 3, text: "3. Hội nhóm, tổ chức người khuyết tật"},
            {value: 4, text: "4. Nhóm tự do tôn giáo"},
        ],
        activityOptions: [
            {value: null, text: "Chọn loại hình"},
            {value: 1, text: "1. Nâng cao năng lực và kiến thức về chính sách"},
            {value: 2, text: "2. Hỗ trợ thúc đẩy triển khai chính sách"},
            {value: 3, text: "3. Hỗ trợ thực thi chính sách"},
            {value: 4, text: "4. Hoạt động khác"},
        ]
    },
    patient: {
        typeOptions: [
            {value: null, text: "Chọn dạng khuyết tật"},
            {value: 1, text: "1. Khuyết tật vận động"},
            {value: 2, text: "2. Khuyết tật nghe, nói"},
            {value: 3, text: "3. Khuyết tật nhìn"},
            {value: 4, text: "4. Khuyết tật thần kinh, tâm thần"},
            {value: 5, text: "5. Khuyết tật trí tuệ"},
            {value: 6, text: "6. Khuyết tật khác"}
        ],
        //Nạn nhân da cam
        victimOptions: [
            {value: null, text: "Chọn nạn nhân da cam"},
            {value: 1, text: "1. Có chứng nhận là nạn nhân da cam"},
            {value: 2, text: "2. Không có chứng nhận là nạn nhân da cam"},
            {value: 3, text: "3. Có yếu tố da cam"},
        ],
        //Nạn nhân bom mìn
        mineVictimOptions: [
            {value: null, text: "Chọn nạn nhân bom mìn"},
            {value: 1, text: "1. Có"},
            {value: 2, text: "2. Không"},
        ],
        degreeDisabilityOptions: [
            {value: null, text: "Chọn mức độ khuyết tật"},
            {value: 1, text: "1. Nhẹ"},
            {value: 2, text: "2. Nặng"},
            {value: 3, text: "3. Đặc biệt nặng"},
            {value: 4, text: "4. Không xác định"},
        ],
        //Cung cấp dụng cụ trợ giúp
        serviceProvider: [
            {value: null, text: "Cung cấp dụng cụ hỗ trợ"},
            {value: 1, text: "1. Có"},
            {value: 2, text: "2. Không"},
        ],
        resultOfEvaluation: [
            {value: null, text: "Chọn kết quả đánh giá"},
            {value: 1, text: "1. Không cải thiện"},
            {value: 2, text: "2. Ổn định"},
            {value: 3, text: "3. Có cải thiện"},
        ],
        toolsOptions: [
            {value: null, text: "Cung cấp dụng cụ trợ giúp"},
            {value: 1, text: "1. Có"},
            {value: 2, text: "2. Không"},
        ],
        interventionOptions: [
            {value: null, text: "Chọn can thiệp tâm lý"},
            {value: 1, text: "1. Có"},
            {value: 2, text: "2. Không"},
        ],
        mentalityOptions: [
            {value: null, text: "Chọn kết quả đánh giá chỉ số rối nhiễu tâm lý"},
            {value: 1, text: "1. Không cải thiện"},
            {value: 2, text: "2. Ổn định"},
            {value: 3, text: "3. Có cải thiện"},
        ],
        resultCare: [
            {value: null, text: "Chọn kết quả đánh giá"},
            {value: 1, text: "1. Không cải thiện"},
            {value: 2, text: "2. Ổn định"},
            {value: 3, text: "3. Có cải thiện"},
        ],
        resultPhcn: [
            {value: null, text: "Chọn kết quả đánh giá"},
            {value: 1, text: "1. Không cải thiện"},
            {value: 2, text: "2. Có cải thiện"},
        ],
        integrateOptions: [
            {value: null, text: "Chọn kết quả đánh giá hỗ trợ hòa nhập"},
            {value: 1, text: "1. Không cải thiện"},
            {value: 2, text: "2. Ổn định"},
            {value: 3, text: "3. Có cải thiện"},
        ],

    },
    patientRelative: {
        relationshipOptions: [
            {value: null, text: "Chọn mối quan hệ với NKT"},
            {value: 1, text: "1. Bố mẹ"},
            {value: 2, text: "2. Vợ chồng"},
            {value: 3, text: "3. Con ruột dâu, rể"},
            {value: 4, text: "4. Khác"},
        ],
        typeOptions: [
            {value: null, text: "Chọn loại hình hỗ trợ được nhận"},
            {value: 1, text: "1. Hỗ trợ chăm sóc"},
            {value: 2, text: "2. Hỗ trợ và can thiệp tâm lý"},
        ],
    },
    patientLife: {
        toolOptions: [
            {value: null, text: "Chọn công cụ đánh giá"},
            {value: 1, text: "1. WHOQOL"},
            {value: 2, text: "2. PedsQL"},
        ],
        resultOptions: [
            {value: null, text: "Chọn kết quả đánh giá"},
            {value: 1, text: "1. Có cải thiện"},
            {value: 2, text: "2. Không cải thiện"},
        ],
        improvementOptions: [
            {value: null, text: "Chọn kết quả đánh giá"},
            {value: 1, text: "1. Sức khỏe thể chất"},
            {value: 2, text: "2. Trạng thái tâm lý"},
            {value: 3, text: "3. Các mối quan hệ xã hội"},
            {value: 4, text: "4. Cảm nhận về môi trường sống"},
            {value: 5, text: "5. Cảm nhận về sự kỳ thị, tự chủ và hòa nhập"},
            {value: 6, text: "6. Sức khỏe thể chất (trẻ em)"},
            {value: 7, text: "7. Cảm xúc (trẻ em)"},
            {value: 8, text: "8. Quan hệ bạn bè (trẻ em)"},
            {value: 9, text: "9. Học tập (trẻ em)"},
        ]
    },
    eduPatient: {
        typeOptions: [
            {value: null, text: "Chọn loại hình cung người cấp dịch vụ"},
            {value: 1, text: "1. Cán bộ PHCN"},
            {value: 2, text: "2. Giáo viên"},
            {value: 3, text: "3. Người chăm sóc cộng đồng"},
            {value: 4, text: "4. Người chăm sóc chuyên nghiệp"},
            {value: 5, text: "5. Khác"},
        ],
        eduTypeOptions: [
            {value: null, text: "Chọn loại hình đào tạo"},
            {value: 1, text: "1. Đào tạo dài hạn"},
            {value: 2, text: "2. Đào tạo trung hạn"},
            {value: 3, text: "3. Đào tạo liên tục"},
            {value: 4, text: "4. Đào tạo chuyên sâu"},
            {value: 5, text: "5. Coaching"},
        ],
        eduTypeEventOptions: [
            {value: null, text: "Chọn loại hình hoạt động sự kiện"},
            {value: 1, text: "1. Đào tạo, tập huấn, workshop"},
            {value: 2, text: "2. Hội thảo"},
            {value: 3, text: "3. Hội thao"},
            {value: 4, text: "4. Ngày hội NKT"},
            {value: 5, text: "5. Khác"},
        ],
        contentEduGenderOptions: [
            {value: null, text: "Chọn nội dung đào tạo giới"},
            {value: 1, text: "1. Nâng cao bình đẳng giới và trao quyền cho phụ nữ"},
            {value: 2, text: "2. Giảm thiểu bạo lực giới"},
        ],
        providerTypeOptions: [
            {value: null, text: "Chọn loại hình người CCDV"},
            {value: 1, text: "1. Cán bộ PHCN"},
            {value: 2, text: "2. Người chăm sóc chính thức"},
            {value: 3, text: "3. Người chăm sóc không chính thức"},
            {value: 4, text: "4. Loại hình khác"},
        ],
    },
    patientOther: {
        typeOptions: [
            {value: null, text: "Chọn loại hình dịch vụ được cung cấp"},
            {value: 1, text: "1. PHCN"},
            {value: 2, text: "2. Chăm sóc y tế"},
            {value: 3, text: "3. Tâm lý"},
            {value: 4, text: "4. Hỗ trợ xã hội"},
        ],
    },
    provinceExport: [
        {value: 75, text: "Tỉnh Quảng Trị"},
        {value: 62, text: "Tỉnh Thừa Thiên Huế"},
        {value: 52, text: "Tỉnh Quảng Nam"},
        {value: 72, text: "Tỉnh Bình Định"},
        {value: 70, text: "Tỉnh Kon Tum"},
        {value: 45, text: "Tỉnh Bình Phước"},
        {value: 46, text: "Tỉnh Tây Ninh"},
        {value: 49, text: "Tỉnh Đồng Nai"},
    ]
};