<template>
    <div>
        <div v-if="isHeader" class="card title">
            <div class="card-body title-filter">
                <div class="row">
                    <div class="col-lg-4 title-filte-left mb-1">
                        <h1 class="title-filter-title">
                            <!--<b-spinner class='b-table-icon' v-if="$parent.listLoading" small></b-spinner>-->
                            <i v-if="!$parent.listLoading && titleIcon && titleIcon.indexOf('fa ') > -1" :class="`b-table-icon ${titleIcon}`" ></i>
                            <b-icon  v-if="!$parent.listLoading && titleIcon && titleIcon.indexOf('fa ') === -1" :icon="titleIcon" class='b-table-icon' aria-hidden="true"></b-icon>
                            <p class="pl-1" v-if="$scopedSlots.titlePage"><slot name="titlePage"/></p>
                            <p class="pl-1" v-else>{{title}}</p>
                        </h1>
                    </div>
                    <div class="col-lg-8 title-filte-right">
                        <slot name="actionBefore"/>
                        <div v-if="filter.keyword != null" class="form-group input-sm mr-2">
                            <b-form-input class="form-control is-search form-control-sm" v-model="filter.keyword" :placeholder="placeholder" @keyup="load"></b-form-input>
                        </div>
                        <slot name="filter"/>
                        <button v-if='isSearch && search' class="btn btn-secondary btn-sm mr-2 ml-2" @click="reset">
                            <i class="fa fa-times" ></i> Bỏ lọc
                        </button>
                        <button v-if='$scopedSlots.filterMore' class="btn btn-link btn-sm mr-2" @click="filterMore = !filterMore;">
                            <span v-if="!filterMore"  ><i class="fa fa-expand" ></i> Mở rộng</span>
                            <span v-if="filterMore" ><i class="fa fa-compress" ></i> Đóng</span>
                        </button>
                        <slot name="action"/>
                        <button v-if="isReset" class="btn btn-gray btn-sm mr-1 ml-1" @click="reset">
                            <b-spinner v-if="$parent.listLoading" small></b-spinner>
                            <i v-else class="fa fa-sync-alt" ></i>
                        </button>
                    </div>
                </div>
                <div v-if="filterMore && $scopedSlots.filterMore" class="row mt-3">
                    <slot name="filterMore" />
                </div>
            </div>
        </div>
        <div class="card title">
            <div class="card-body title-body">
                <div class="tile-title is-line">
                    <div v-if="$scopedSlots.title">
                        <slot name="title" :selected="selected" :allSelected="allSelected" />
                    </div>
                    <div v-else class="row text-black size-14">
                        <div class="col-lg-4 col-xs-6">
                            <span v-if="itemCount"> 
                                Đang xem {{firstItemOfPage | numFormat}}-{{lastItemOfPage | numFormat}}/{{itemCount | numFormat}}
                            </span> 
                            <span v-else >Danh sách </span>
                            <span class="text-lowercase">{{title}}</span>
                        </div>
                        <div class="col-lg-8 col-xs-6 form-inline action-right">
                            <slot name="actionRight" :selected="selected" :allSelected="allSelected" />
                        </div>
                    </div>
                </div>
                <div v-if="$parent.listLoading" class="text-center text-primary">
                    <b-spinner></b-spinner> 
                    <div>Đang tải dữ liệu ...</div>
                </div>
                <div v-if="!$parent.listLoading && !itemCount" class="text-center text-danger">
                    Không có dữ liệu!
                </div>
                <div v-if="!$parent.listLoading && itemCount" :class="config.fixed ? 'title-template-table  table-responsive title-table-head-fixed': 'title-template-table  table-responsive'">
                    <div v-if="$scopedSlots.table">
                        <slot name="table" />
                    </div>
                    <b-table v-else v-bind="$attrs" v-on="$listeners" ref="table"
                             striped no-local-sorting :thead-class="isHeaderTable ? '': 'd-none'"
                             selectable :select-mode="selectMode" @row-selected="onRowSelected"
                             :bordered="config.bordered" :small="config.small"  hover 
                             :items="items" :fields="tableFields"
                             class="table table-hover table-head-fixed" @sort-changed="sortChanged">
                        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                            <slot v-if="!['filter', 'filterMore'].includes(slot)" :name="slot" v-bind="scope" />
                        </template>

                        <template #head(selected)="{}">
                            <div @click.stop="toggleSelected" >
                                <i v-if="allSelected && selected.length > 0 && selected.length != items.length" class="fa fa-minus-square icon-table"></i>
                                <i v-else-if="allSelected && selected.length > 0" class="fa fa-check-square icon-table"></i>
                                <i v-else class="far fa-square icon-table"></i>
                            </div>
                        </template>
                        <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <i class="fa fa-check-square icon-table"></i>
                            </template>
                            <template v-else>
                                <i class="far fa-square icon-table"></i>
                            </template>
                        </template>
                    </b-table>
                </div>
            </div>
            <div v-if="isFooter" :class="isFooterFix ? 'card-footer title-footer clearfix p-2 title-footer-fixed': 'card-footer title-footer clearfix p-2'">
                <div class="row">
                    <div class="col-lg-6 form-inline">
                        <b-dropdown aria-role="list" variant="link" class="page-title">
                            <template #button-content>Đang xem {{firstItemOfPage | numFormat}}-{{lastItemOfPage | numFormat}}/{{itemCount | numFormat}} <span class="text-lowercase">{{title}}</span></template>
                            <b-dropdown-item v-for="s in sizeOptions" :key="s.value" 
                                             :active="s.value === filter.size"
                                             @click="setSize(s.value)">
                                {{s.text}}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item :active="config.fixed" @click="setConfig('fixed', !config.fixed)">Giữ tiêu đề bảng</b-dropdown-item>
                            <b-dropdown-item :active="config.bordered" @click="setConfig('bordered', !config.bordered)">Viền bảng</b-dropdown-item>
                            <b-dropdown-item :active="config.small" @click="setConfig('small', !config.small)">Thu gọn bảng</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="col-lg-6">
                        <b-pagination v-if="itemCount > filter.size" size="sm" class="pagination pagination-sm float-right pb-0 mb-0"
                                      v-model="filter.page" :per-page="filter.size" :total-rows="itemCount" @change="setPage">
                        </b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: "gridTable",
        props: {
            title: {type: String, required: true},
            titleIcon: {type: String, required: false},
            filter: {type: Object, required: true},
            itemCount: {type: Number, required: false, default: 0},
            items: {type: Array, required: true},
            fields: {type: Array, required: true},
            selectMode: {type: String, default: 'single'},
            isHeader: {type: Boolean, default: true},
            isFooter: {type: Boolean, default: true},
            isSelected: {type: Boolean, default: false},
            isFooterFix: {type: Boolean, default: false},
            isReset: {type: Boolean, default: true},
            isHeaderTable: {type: Boolean, default: true},
            isSearch: {type: Boolean, default: true},
            placeholder: {type: String, default: 'Từ khoá ...'},
        },
        data() {
            return {
                allSelected: false,
                search: false,
                selected: [],
                filterMore: false,
                sizeOptions: [
                    {value: 5, text: 'Hiển thị 05 dòng'},
                    {value: 20, text: 'Hiển thị 20 dòng'},
                    {value: 50, text: 'Hiển thị 50 dòng'},
                    {value: 100, text: 'Hiển thị 100 dòng'},
                    {value: 200, text: 'Hiển thị 200 dòng'}
                ],
                config: {
                    bordered: false,
                    small: false,
                    fixed: false,
                }
            }
        },
        watch: {
            items: {
                handler() {
                    if (this.$refs.table) {
                        this.selected = [];
                        this.$refs.table.clearSelected();
                    }
                }, deep: true
            },
            filter: {
                handler(val) {
                    if (val) {
                        if (this.$refs.table) {
                            this.$refs.table.clearSelected();
                        }
                        let vm = this;
                        vm.search = false;
                        if (this.$parent.defaultFilterFormModel) {
                            vm.search = JSON.stringify(this.$parent.defaultFilterFormModel) !== JSON.stringify(val);
                        } else {
                            Object.keys(val).map(function (key) {
                                if (!['page', 'size'].includes(key) && val[key] !== null && val[key] !== '' && val[key] !== 0) {
                                    vm.search = true;
                                    return false;
                                }
                            });
                        }
                    }
                }, deep: true
            }
        },
        computed: {
            firstItemOfPage() {
                return this.itemCount > 0 ? (this.filter.page - 1) * this.filter.size + 1 : 0;
            },
            lastItemOfPage() {
                return ((this.filter.page - 1) * this.filter.size) + this.items.length;
            },
            tableFields() {
                return this.isSelected ? [{key: 'selected', label: ""}].concat(this.fields) : this.fields;
            }
        },
        methods: {
            toggleSelected() {
                this.allSelected = !this.allSelected;
                if (this.allSelected) {
                    this.$refs.table.selectAllRows();
                } else {
                    if (this.$refs.table) {
                        this.$refs.table.clearSelected();
                    }
                }
            },
            onRowSelected(items) {
                if (items && items.length > 0) {
                    this.allSelected = true;
                }
                this.selected = items;
            },
            load: _.debounce(function () {
                if (this.$refs.table) {
                    this.$refs.table.clearSelected();
                }
                this.filter.page = 1;
                this.$emit('load');
            }, 500),
            reset() {
                if (this.$refs.table) {
                    this.$refs.table.clearSelected();
                }
                this.$emit('reset');
            },
            setPage(page) {
                this.filter.page = page;
//                this.load();
                this.$emit('load');
            },
            setSize(size) {
                this.filter.page = 1;
                this.filter.size = size;
                this.load();
            },
            setConfig(key, value) {
                this.config[key] = value;
                localStorage.setItem("gridConfig", JSON.stringify(this.config));
            },
            sortChanged(evt) {
                this.filter.sortBy = evt.sortBy;
                this.filter.sortDesc = evt.sortDesc ? 1 : 0;
                this.filter.page = 1;
                this.load();
            },
        },
        mounted() {
            let store = localStorage.getItem("gridConfig");
            if (store) {
                this.config = JSON.parse(store);
            }
        }
    }
</script>