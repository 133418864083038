import Vue from 'vue';
import Vuex from 'vuex';
import {bus} from './bus';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        token: null,
        roles: null,
        gitVersion: null,
        etc: null,
        authEnable: false,
        client: null,
    },
    mutations: {
        user(state, user) {
            state.user = user;
            state.gitVersion = user ? user.git : "dev";
            state.etc = user ? user.etc : null;
            state.roles = user ? user.roles : null;
            state.authEnable = user ? user.authEnable : false;
            state.client = user ? user.client : null;
        },
        token(state, token) {
            state.token = token;
            let authorization = btoa(`${token}:`);
            Vue.prototype.$service.defaults.headers.common['Authorization'] = `Basic ${authorization}`;
        }
    },
    actions: {
        login( {commit}, data) {
            if (data.remember) {
                localStorage.setItem('token', data.token);
            }
            sessionStorage.setItem('token', data.token);
            commit('token', data.token);
            return Vue.prototype.$service.get('/site/profile').then(response => {
                commit('user', response.data);
                bus.$emit('initSocket');
            });
        },
        logout( {commit}) {
            Vue.prototype.$service.post('/site/logout', {token: this.state.token}).then(() => {
            });
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            commit('user', null);
            commit('token', null);
            bus.$emit('closeSocket');
            return Promise.resolve();
        }
    }
})
