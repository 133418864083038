<template>
    <body :class="bodyClass" id="app">
        <div v-if="$store.state.user"  class="wrapper">
            <app-nav></app-nav>
            <div class="content-wrapper pt-3">
                <div class="content">
                    <router-view></router-view>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
        <template v-else >
            <router-view></router-view>
        </template>
    </body>
</template>


<style type="text/css">
    @import './assets/css/main.css';
</style>

<script>
    import './assets/js/main.js';
    import Nav from "./components/Nav";
    import Footer from "./components/Footer";
    import 'vue-multiselect/dist/vue-multiselect.min.css';

    export default {
        name: 'App',
        components: {
            'app-nav': Nav,
            'app-footer': Footer
        },
        computed: {
            bodyClass() {
                let cls = 'hold-transition layout-top-nav text-sm';
                if (!this.$store.state.user) {
                    cls = 'auth-body';
                }
                return cls;
            },
            currentRoute() {
                return this.$router.currentRoute.path;
            },
        },
        async mounted() {
            let etc = this.$utils.getWithExpiry('etc');
            if (!etc) {
                let rep = await this.$service.get('/site/etc');
                etc = rep.data;
                this.$utils.setWithExpiry('etc', JSON.stringify(etc), 60 * 60 * 1000);
            } else {
                etc = JSON.parse(etc);
            }
            let style = document.documentElement.style;
            style.setProperty("--theme-color-bold", etc && etc['site-color-bold'] ? etc['site-color-bold'] : '#07144a');
            style.setProperty("--theme-color-hover", etc && etc['site-color-hover'] ? etc['site-color-hover'] : '#dadaee');
            style.setProperty("--theme-color", etc && etc['site-color'] ? etc['site-color'] : '#2C3D83');

            if (etc && etc['site-ico']) {
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = etc['site-ico'];
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            if (this.$store.state.client) {
                let title = document.querySelector("title") || document.createElement('title');
                title.textContent = this.$store.state.client.short_name;
                document.getElementsByTagName('head')[0].appendChild(title);
            } else if (etc && etc['site-name']) {
                let title = document.querySelector("title") || document.createElement('title');
                title.textContent = etc['site-name'];
                document.getElementsByTagName('head')[0].appendChild(title);
            }
        }
    }
</script>

<style lang='scss' >
    $color: var(--theme-color);
    $color-hover: var(--theme-color-hover);
    $color-hover-bold: var(--theme-color-bold);

    .c-report-item__count {
        color: $color;
    }

    .c-report-item figure .fa {
        color: $color;
    }

    .c-smart-select__current label {
        background-color: $color;
    }
    .dropdown-menu.is-custom .dropdown-menu__inner .ul-list>li .nolink:focus,
    .dropdown-menu.is-custom .dropdown-menu__inner .ul-list>li .nolink:hover,
    .dropdown-menu.is-custom .dropdown-menu__inner .ul-list>li>a:focus,
    .dropdown-menu.is-custom .dropdown-menu__inner .ul-list>li>a:hover {
        background-color: $color-hover;
    }
    .navbar-nav .dropdown-menu li.active {
        background-color: $color-hover;
    }
    .navbar-nav .dropdown-menu li.active > a {
        color: #fff;
    }
    .navbar-nav .dropdown-menu li.active > a:hover {
        color: #fff;
    }
    .navbar-cyan {
        background-color: $color;
    }
    .title .title-filter .title-filter-title {
        color: $color !important;
    }
    .title .tile-title {
        color: $color;
    }
    .c-sidebar-tiny ul li.active a {
        background-color: $color;
        border-color: $color;
    }
    .c-tabs-button .nav .nav-link.active {
        background-color: $color !important;
    }

    .btn-primary {
        background-color: $color;
        border-color: $color;
    }
    .btn-primary:hover {
        background-color: $color-hover-bold;
    }
    .bg-gray {
        background-color: $color !important;
    }
    .text-gray {
        color: $color !important;
    }
    .btn-gray {
        background-color: $color;
        border-color: $color;
    }
    .btn-gray:hover {
        background-color: $color-hover-bold;
        color: #fff;
    }
    .btn-outline-gray .fa, .btn-outline-gray svg {
        color: $color;
    }
    .btn-outline-gray:hover, .btn-outline-gray.active {
        background-color: $color-hover-bold;
        border-color: $color-hover-bold;
    }

    .btn-primary.disabled, .btn-primary:disabled {
        color: #FFF;
        background-color: $color-hover;
        border-color: $color-hover;
    }

    .c-sidebar-tiny ul li:hover a {
        background-color: $color;
        color: #fff
    }
    .c-language-text ul li.active a {
        color: $color;
    }
    .auth-page .social_icon span{
        color: $color;
    }
    .auth-page .input-group-prepend span{
        background-color: $color;
    }
    .auth-page .login_btn{
        background-color: $color;
    }
    .c-arrow-nav .nav-link.active {
        border-color: $color;
        background-color: $color;
    }
    .c-arrow-nav .nav-link.active:before {
        border-left: 12px solid $color;
    }

    .multiselect .multiselect__tag {
        margin-bottom: 0px;
        background: $color;
    }
    .multiselect .multiselect__option--highlight {
        background: $color;
    }
    .multiselect .multiselect__tag-icon:hover {
        background: $color;
    }
    .multiselect .multiselect__tag {
        margin-bottom: 0px;
        background: $color;
    }
    .multiselect .multiselect__option--highlight {
        background: $color;
    }
    .multiselect .multiselect__option--highlight:after {
        background: $color;
    }
    .c-header .navbar-nav .nav-item.active {
        background-color: $color-hover-bold;
    }
</style>
