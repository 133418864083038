<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state">
        <template #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <div v-if="placeholder" class="alert alert-info">{{placeholder}}</div>
        <div v-if="$scopedSlots.before">
            <slot name="before" />
        </div>
        <editor v-model="inputVal" api-key="fa2kaeu07x3kwc62fg2zzqtk817c0u54ewtj6apb2qyjymmh" :init="config"></editor>
        <file-select :showModal="show_modal" @close="show_modal = false" @selected="selectFiles" :multiple="false" :server="server"/>
    </b-form-group>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import FileSelect from '@/components/media/FileSelect';

    export default {
        components: {
            'editor': Editor,
            FileSelect
        },
        props: {
            model: Object,
            attribute: String,
            label: String,
            errors: {},
            placeholder: String,
            maxLength: {type: Number, default: 20000},
            errorOnly: Boolean,
            required: Boolean,
            height: {type: Number, default: 500}
        },
        data() {
            return {
                server: {
                    home: 'media',
                    add_folder: 'media/add-folder',
                    file_uploads: 'media/upload',
                    update: 'media/update'
                },
                show_modal: false,
                callback: null,
                inputVal: this.model[this.attribute] ? this.model[this.attribute] : null,
                state: null,
                errorTxt: '',
                config: {
                    height: this.height,
                    plugins: [
                        'lists', 'image', 'table', 'codesample', 'fullscreen', 'code',
                        'wordcount', 'quickbars', 'preview', 'searchreplace',
                        'directionality', 'visualblocks', 'visualchars', 'image', 'link', 'media',
                        'template', 'charmap', 'hr', 'pagebreak', 'nonbreaking', 'anchor',
                        'insertdatetime', 'advlist', 'textpattern', 'noneditable', 'help',
                        'charmap quickbars emoticons'
                    ],
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | image | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    image_advtab: true,
                    image_caption: true,
                    file_picker_types: 'file image media',
                    file_picker_callback: this.fileCallback,
                }
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    this.inputVal = val[this.attribute];
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            selectFiles(file) {
                this.files_selected = true;
                this.show_modal = false;
                if (this.callback && file) {
                    if (['doc', 'docx', 'xlsx', 'pdf'].includes(file.extension)) {
                        this.callback(file.url, {text: file.desc ? file.desc : (file.title ? file.title : file.name)});
                    } else if (['mp4'].includes(file.extension)) {
                        this.callback(file.url, {source2: file.url});
                    } else {
                        this.callback(file.url, {
                            alt: file.desc ? file.desc : (file.title ? file.title : file.name),
                        });
                    }
                }
            },
            fileCallback(callback) {
                this.callback = callback;
                this.show_modal = true;
            }
        }
    }
</script>