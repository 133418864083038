import GridTable from "./GridTable";
import GridButton from "./GridButton";
import GridLoading from "./GridLoading";

export default {
    install(Vue) {
        Vue.component('g-table', GridTable);
        Vue.component('g-button', GridButton);
        Vue.component('g-loading', GridLoading);
    }
}