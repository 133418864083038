<template>
    <b-modal v-model="showForm" size="xl" title="Thêm media" 
             @ok="$emit('selected', selected_files)" id="mediaModel"
             @close="$emit('close')" no-close-on-backdrop no-enforce-focus>
        <g-loading v-if="firstLoading" />
        <div class="row" v-else>
            <div class="col-2 col-folder">
                <ol class="breadcrumb p-1 mb-1">
                    <li class="breadcrumb-item">
                        <b class="ml-3"><i class="fa fa-folder"></i> Thư mục ({{dirs.length}})</b>
                    </li>
                </ol>
                <ul class="list-group list-group-flush">
                    <li @click.prevent="goBack('')" :class="[`` === dir ? 'list-group-item active': 'list-group-item']" style="cursor: pointer" >
                        <i class="fa fa-folder" ></i>
                        Thư mục root
                    </li>
                    <li v-for="(d, i) in dirs" :key="`root_${i}`" :class="[`${d}/` === dir ? 'list-group-item active': 'list-group-item']" @click.prevent="fetchDir(d, true)" style="cursor: pointer" >
                        <i class="fa fa-folder" ></i>
                        {{d}}
                    </li>
                </ul>
            </div>
            <div class="col-md col-main" v-if="!cropImage">
                <div class="row">
                    <div class="col-md-7">
                        <ol class="breadcrumb p-1 mb-1">
                            <li class="breadcrumb-item" @click.prevent="goBack('')" style="cursor: pointer"><b><i class="fa fa-hashtag" ></i> Media <span v-if="!breadcrumbs || !breadcrumbs.length">({{files.length}})</span></b></li>
                            <li class="breadcrumb-item" v-for="(crumb, i) in breadcrumbs" :key="i"><b>Thư mục: {{crumb}} ({{files.length}})</b></li>
                        </ol>
                    </div>
                    <div class="col-md text-right">
                        <button class="btn btn-info btn-sm mr-1" @click.prevent="show_add_folder = !show_add_folder">
                            <i class="fa fa-plus"></i> Tạo thư mục
                        </button>

                        <button class="btn btn-primary btn-sm  mr-1" @click.prevent="selectFiles">
                            <i class="fa fa-upload"></i> Tải tập tin
                        </button>
                        <button class="btn btn-primary btn-sm  mr-1" @click="cropImage = true">
                            <i class="fa fa-crop"></i> Crop hình ảnh
                        </button>

                        <input type="file" style="display: none" ref="upload_files" @input="uploadFiles" multiple id="file_uploads_selector" :accept="accept" />
                    </div>
                </div>
                <div v-if="listLoading">
                    <div class='col-12'>
                        <g-loading />
                    </div>
                </div>
                <div class="row" v-else> 
                    <div class="col-12" v-if="show_add_folder">
                        <b-input-group class="input-group-sm">
                            <b-form-input type="text" placeholder="Nhập tên thư mục" v-model="new_folder" />
                            <b-input-group-append>
                                <button class="btn btn-primary btn-sm" @click.prevent="addFolder">Tạo thư mục</button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div class="col-12">
                        <div class="attachments-browser">
                            <ul tabindex="-1" class="attachments ui-sortable ui-sortable-disabled" id="__attachments-view-803">
                                <li v-for="(file, i) in files" :key="`file_${i}`" tabindex="0" :class="active(file)">
                                    <div class="attachment-preview js--select-attachment type-image subtype-jpeg portrait">
                                        <label class="thumbnail" :for="`file_${file.id}`">
                                            <input :type="multiple ? 'checkbox' : 'radio'" :id="`file_${file.id}`"
                                                   :value="file" v-model="selected_files" style="display: none;" />
                                            <div class="centered">
                                                <video  v-if="['mp4', 'm4v'].includes(file.extension)" width="200" height="100" controls>
                                                    <source :src="file.url" :type="file.type">
                                                    Your browser does not support the video tag.
                                                </video>
                                                <i v-else-if="['doc','docx'].includes(file.extension)" class="far fa-file-word"></i>
                                                <i v-else-if="['xlsx'].includes(file.extension)" class="far fa-file-excel"></i>
                                                <i v-else-if="['pdf'].includes(file.extension)" class="far fa-file-pdf"></i>
                                                <i v-else-if="['mp3'].includes(file.extension)" class="far fa-file-audio"></i>
                                                <i v-else-if="['csv'].includes(file.extension)" class="fa fa-file-csv"></i>
                                                <i v-else-if="['zip'].includes(file.extension)" class="far fa-file-archive"></i>
                                                <img v-else :src="file.url" :alt="file.name" />

                                                <div class='file-name' v-if="['doc','docx', 'xlsx', 'pdf', 'mp4', 'm4v', 'mp3', 'zip', 'csv'].includes(file.extension)">{{line(file.name, 10) }}</div>
                                            </div>
                                        </label>
                                    </div>
                                    <button type="button" class="check" tabindex="-1">
                                        <span class="media-modal-icon"></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md col-main" v-else>
                <div class="row">
                    <div class="col-8">
                        <ol class="breadcrumb p-1 mb-1">
                            <li class="breadcrumb-item" style="cursor: pointer"><b><i class="fa fa-hashtag" ></i> Tải hình ảnh và crop trực tiếp</b></li>
                            <li class="breadcrumb-item" v-for="(crumb, i) in breadcrumbs" :key="i"><b>Thư mục: {{crumb}} ({{files.length}})</b></li>
                        </ol>
                    </div>
                    <div class="col-md text-right">
                        <button class="btn btn-secondary btn-sm  mr-1" @click="cropImage = false">
                            <i class="fa fa-times"></i> Quay lại
                        </button>
                    </div>
                    <div class="col-12">
                        <vue-anka-cropper :options="cropOptions" 
                                          @cropper-saved="cropperSaved"
                                          @cropper-preview="cropperPreview"></vue-anka-cropper>
                    </div>
                </div>
            </div>
            <div class="col-2 media-sidebar">
                <ol class="breadcrumb p-1 mb-1" v-if="formModel">
                    <li class="breadcrumb-item">
                        <b class="ml-3"><i class="fa fa-info"></i> Chi tiết đính kèm</b>
                    </li>
                </ol>
                <div class="detail" v-if="formModel">
                    <div class="attachment-info">
                        <audio v-if="['mp3'].includes(formModel.extension)" controls id="audio">
                            <source :src="formModel.url" :type="formModel.type">
                        </audio>
                        <div v-else class="thumbnail thumbnail-image">
                            <video  v-if="['mp4', 'm4v'].includes(formModel.extension)" width="200" height="100" controls>
                                <source :src="formModel.url" :type="formModel.type">
                                Your browser does not support the video tag.
                            </video>
                            <i v-else-if="['doc','docx'].includes(formModel.extension)" class="far fa-file-word"></i>
                            <i v-else-if="['xlsx'].includes(formModel.extension)" class="far fa-file-excel"></i>
                            <i v-else-if="['pdf'].includes(formModel.extension)" class="far fa-file-pdf"></i>
                            <img v-else :src="formModel.url" :alt="formModel.name" />
                        </div>
                        <div class="details">
                            <div class="filename">{{formModel.name}}</div>
                            <div class="uploaded">{{formModel.createTime | datetimefullFormat}}</div>
                            <div class="file-type">Định dạng: {{formModel.type}}</div>
                            <div class="file-size" v-if="formModel.size > 0" >{{formModel.size | numFormat}} KB</div>
                            <div class="dimensions" v-if="formModel.width > 0" >
                                dài {{formModel.width}} và rộng {{formModel.height}} pixel						
                            </div>
                            <div class="mt-2">
                                <g-button v-if="multiple ? (selected_files && selected_files.length) : (selected_files && selected_files.id)" 
                                          :loading="delLoading" size="sm" variant="outline-danger" class="ml-1" icon="trash-fill" 
                                          @click='del(formModel.id)' content="Xoá" />
                            </div>
                        </div>
                    </div>
                    <form-input :required='true'  label="Tiêu đề" :model="formModel" attribute="title" :errors="formErrors" />
                    <form-textarea label="Chú thích" :model="formModel" :errors="formErrors" attribute="desc"/>
                    <form-input :disabled="true" label="Đường dẫn" :model="formModel" attribute="url" :errors="formErrors" append="Sao chép" />
                    <b-button :disabled="saveLoading" variant="primary" class="float-right mb-2 btn-sm" @click="update">
                        <b-spinner v-if="saveLoading" small></b-spinner>
                        Cập nhật
                    </b-button>
                    <b-button :disabled="saveLoading" variant="link" class="float-right mb-2 mr-2 btn-sm"  @click="copy(formModel.url)">
                        <i class="fa fa-link"></i>
                        Sao chép link
                    </b-button>
                </div>
            </div>
        </div>

        <template v-slot:modal-footer="{ ok}">
            <b-button variant="secondary" class="float-right" @click="$emit('close')">Đóng</b-button>
            <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                <b-spinner v-if="saveLoading" small></b-spinner>
                <em v-else class="fa fa-check" ></em>
                Sử dụng
            </b-button>
        </template>
    </b-modal>
</template>
<style>
    @import '../../../node_modules/vue-anka-cropper/dist/VueAnkaCropper.css';
    .ankaCropper {
        font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    }
    .ankaCropper__navButton, .ankaCropper__saveButton {
        height: auto!important;
    }
    .ankaCropper__navButton {
        width: auto;
    }
</style>
<script>
    import vueAnkaCropper from 'vue-anka-cropper';

    export default {
        props: {
            showModal: {
                type: Boolean,
                default() {
                    return true;
                },
            },
            multiple: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            server: {
                type: Object,
                default() {
                    return {home: '', add_folder: '', file_uploads: '', update: ''};
                },
            },
            accept: {
                type: String,
                default() {
                    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg, image/jpg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                },
            },
        },
        components: {vueAnkaCropper},
        data() {
            return {
                files: [],
                dirs: [],
                dir: '',
                firstLoading: true,
                listLoading: false,
                saveLoading: false,
                delLoading: false,
                selected_files: [],
                show_add_folder: false,
                new_folder: '',
                showForm: this.showModal,
                formModel: null,
                formErrors: {},
                cropImage: false,
                cropOptions: {
                    aspectRatio: false,
                    resultMimeType: 'image/png',
                    dropareaMessage: "Kéo thả hình ảnh hoặc sử dụng nút bên dưới",
                    selectButtonLabel: 'Chọn hình ảnh',
                }
            };
        },
        methods: {
            copy(str) {
                this.$copyText(str).then(() => {
                    this.$swal({
                        text: `Đã copy ${str}`,
                        icon: 'success',
                        toast: true,
                        position: 'bottom-end',
                        timer: 3000
                    });
                }, () => {
                    this.$swal({
                        title: "Có lỗi",
                        text: "Copy thất bại",
                        icon: 'error'
                    });
                })
            },
            active(item) {
                if (Array.isArray(this.selected_files) && this.selected_files.find(f => f.id == item.id)) {
                    return 'attachment save-ready selected details';
                } else if (item.id == this.selected_files.id) {
                    return 'attachment save-ready selected details';
                }
                return 'attachment save-ready';
            },
            del: function () {
                let ids;
                if (this.multiple) {
                    ids = this.selected_files.map(item => item.id).join(',');
                } else {
                    ids = [this.selected_files.id];
                }

                this.$swal({
                    title: "Bạn chắc chắn muốn xóa chứ?",
                    text: "Sẽ không thể khôi phục lại dữ liệu đã xóa!",
                    icon: "warning",
                    showCancelButton: true
                }).then((result) => {
                    if (result.value) {
                        this.delLoading = true;
                        this.$service.delete(`${this.server.home}/del`, {params: {ids: ids.join(",")}}).then(response => {
                            if (response.data) {
                                this.fetch();
                                this.$swal({
                                    title: "Thành công",
                                    text: "Xoá thành công",
                                    icon: 'success',
                                    toast: true,
                                    position: 'bottom-end',
                                    timer: 5000,
                                });
                            } else {
                                this.$swal({
                                    title: "Có lỗi",
                                    text: "Xoá không thành công",
                                    icon: 'error',
                                    toast: true,
                                    position: 'bottom-end',
                                    timer: 5000,
                                });
                            }
                        }).catch(error => {
                            if (error.response.data.message) {
                                this.$swal({
                                    text: error.response.data.message,
                                    icon: 'error'
                                });
                            }
                        }).finally(() => {
                            this.delLoading = false;
                        });
                    }
                });
            },
            line(line, length) {
                return line ? line.substr(0, length) : "--";
            },
            fetch() {
                this.listLoading = true;
                this.selected_files = [];
                this.$service.get(this.server.home, {
                    params: {
                        path: this.dir,
                        accept: this.accept
                    }
                }).then(response => {
                    this.files = response.data.files;
                    this.dirs = response.data.dirs;
                    this.firstLoading = false;
                    this.listLoading = false;
                });
            },
            fetchDir(dir, root) {
                if (!root) {
                    this.dir += dir + '/';
                } else {
                    this.dir = dir + '/';
                }
                this.fetch();
            },
            goBack(dir) {
                dir = dir ? dir : this.dir;
                let deep = dir.split('/');
                if (dir.endsWith("/")) {
                    deep.splice(deep.length - 1, 1);
                }
                deep.splice(deep.length - 1, 1);

                this.dir = deep.join('/');
                this.fetch();
            },
            addFolder() {
                let data = {
                    path: this.dir,
                    name: this.new_folder,
                };
                this.$service.post(this.server.add_folder, data).then(() => {
                    this.show_add_folder = false;
                    this.dirs.push(this.new_folder);
                    this.new_folder = '';
                });
            },
            selectFiles() {
                let input_field = document.getElementById('file_uploads_selector');
                input_field.click();
            },
            uploadFiles() {
                this.saveLoading = true;
                let files = this.$refs.upload_files.files;

                let data = new FormData();
                data.append('path', this.dir);
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];
                    data.append('uploaded_files[' + i + ']', file);
                }
                this.$service.post(this.server.file_uploads, data, {headers: {'content-type': 'multipart/form-data'}}).then(() => {
                    this.saveLoading = false;
                    this.fetch();
                });
            },
            update: function (evt) {
                if (evt) {
                    evt.preventDefault();
                }
                this.saveLoading = true;
                let method = 'put';
                let url = `${this.server.update}?id=${this.formModel.id}`;
                if (this.beforeSave) {
                    this.beforeSave();
                }
                return this.$service({url: url, method: method, data: this.formModel}).then(response => {
                    if (this.load) {
                        this.load();
                    }
                    if (this.reloadBus) {
                        this.$bus.$emit(this.reloadBus);
                    }
                    if (response.data) {
                        this.$swal({
                            title: "Thành công",
                            text: this.formModel.id ? 'Chỉnh sửa thành công' : 'Thêm mới thành công',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    } else {
                        this.$swal({
                            title: "Có lỗi",
                            text: this.formModel.id ? 'Chỉnh sửa thất bại' : 'Thêm mới thất bại',
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    }
                    return response.data;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    } else {
                        this.$swal({
                            text: "Vui lòng kiểm tra lại thông tin",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                        });
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
            cropperSaved: function (cropData) {
                this.saveLoading = true;
                let data = new FormData();
                data.append('path', this.dir);
                data.append('uploaded_files[0]', cropData.croppedFile);
                this.$service.post(this.server.file_uploads, data, {headers: {'content-type': 'multipart/form-data'}}).then(() => {
                    this.saveLoading = false;
                    this.cropImage = false;
                    this.fetch();
                });
            },
            cropperPreview: function (cropData) {
                let img = new Image();
                img.onload = function () {
                    let info = document.getElementById("ankaCropper__previewArea_info");
                    if (!info) {
                        info = document.createElement("div");
                        info.id = "ankaCropper__previewArea_info";
                        document.getElementsByClassName("ankaCropper__mainArea")[0].prepend(info);
                        info = document.getElementById("ankaCropper__previewArea_info")
                    }
                    info.innerHTML = `<div class="mb-2">Kích thước: ${this.height}px x ${this.width}px</div>`;
                }
                img.src = cropData;
            }
        },
        computed: {
            breadcrumbs() {
                return this.dir.split('/').filter(crumb => {
                    return crumb != '';
                });
            },
        },
        watch: {
            showModal(newval) {
                this.showForm = newval;
                if (newval) {
                    this.fetch();
                }
            },
            selected_files: {
                handler(val) {
                    this.formModel = null;
                    if (Array.isArray(val)) {
                        this.formModel = val && val.length ? val[val.length - 1] : null;
                    } else {
                        this.formModel = val;
                    }
                }, deep: true
            },
        },
    };
</script>