<template>
    <b-form-group :label="label" :invalid-feedback="errorTxt" :state="state">
        <template v-if="required" #label>
            {{label}} <span v-if="required" class="text-danger">*</span>
        </template>
        <date-picker v-model="inputVal" :config="options" @dp-change="$emit('change')"
                     :placeholder="placeholder" class="form-control-sm"></date-picker>
    </b-form-group>
</template>

<script>
    import moment from 'moment';
    import VueBootstrapDatetimePicker from 'vue-bootstrap-datetimepicker';

    export default {
        props: {
            model: Object,
            attribute: String,
            label: String,
            placeholder: String,
            errors: Object,
            readonly: Boolean,
            format: {
                type: String,
                default: 'D MMMM YYYY'
            },
            errorOnly: Boolean,
            required: Boolean
        },
        components: {
            'date-picker': VueBootstrapDatetimePicker
        },
        data() {
            return {
                inputVal: this.model[this.attribute] ? moment(this.model[this.attribute], 'x').format(this.format) : null,
                state: null,
                errorTxt: '',
                options: {
                    format: this.format,
                    useCurrent: false
                }
            }
        },
        watch: {
            format() {
                this.options = {
                    format: this.format,
                    useCurrent: false
                }
            },
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute]) {
                        this.inputVal = moment(val[this.attribute], 'x').format(this.format);
                    } else {
                        this.inputVal = null;
                    }
                }, deep: true
            },
            inputVal(val) {
                this.state = null;
                this.errorTxt = '';
                try {
                    let time = moment(val, this.format);
                    this.model[this.attribute] = moment(time).valueOf();
                } catch (e) {
                    this.model[this.attribute] = 0;
                }
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
    }
</script>