<template>
    <skeleton-loading>
        <row>
            <square-skeleton :boxProperties="{bottom: '10px', height: '30px'}"></square-skeleton>
        </row>
        <row>
            <square-skeleton :boxProperties="{bottom: '10px', height: '200px'}"></square-skeleton>
        </row>
    </skeleton-loading>
</template>

<script>
    export default {
    }
</script>